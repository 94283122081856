.v2200-ArBaddebtContractIndex{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 580px;
  .mr3{
    margin-right: 3px;
  }
  .baddebtList{
    margin: 12px 0;
    padding: 12px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    font-size: 12px;
    word-break: break-all;
    position: relative;
    .contractDialogLoading{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .loading-main{
        position: relative;
        width: 100%;
        height: 100%;
      }
      // 动画_样式
      @keyframes v1123_relative_identifier {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
      }
      .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 30px;
          right: 0;
          background-color: rgba(255, 255, 255, .8);
      }
      .loading {
          width: 32px;
          height: 32px;
          position: absolute;
          left: calc(50%);
          top: calc(50%);
          margin: -16px 0 0 -16px;
          animation: v1123_relative_identifier 1s linear infinite;
          z-index: 55;
          img{
            width: 32px;
            height: 32px;
          }
      }
    }
    .list-title{
      font-weight: 700;
      color: #303133;
    }
    .list-money{
      color: #333333;
      margin-top: 6px;
      .money-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .bad-money{
        flex: 1;
        margin-right: 8px;
      }
      .link-contract{
        align-items: center;
        display: flex;
        cursor: pointer;

        img{
          width: 14px;
          height: 14px;
          margin-left: 4px;
          transition: all .3s;
        }
        .img-up{
          transform: rotateX(-180deg);
        }
      }
    }
  }
  .baddebtMain{
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  .mlr5{
    margin: 0 5px;
  }
  .mt8{
    margin-top: 8px;
  }
  .fontW700{
    font-weight: 700;
  }

  .contract-ct{
    padding: 8px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    word-break: break-all;
    margin: 8px 0;
    background: #F3F6FA;
    color: #333333;

    .color-FF475A{
      color: #FF475A;
    }
    .w260{
      min-width: 260px;
    }
  
    .money-date{
      display: flex;
      align-items: center;
    }


  }
  .childList{
    font-size: 12px;

    .selLinkContract{
      color: #3377FF;
      line-height: 18px;
      cursor: pointer;
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  // 子合同 
  .baddebtChildren{
    font-size: 12px;
    .contract-ct{
      background: #FFFFFF;
      padding: 12px 20px;
      margin: 12px 0;
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }



}