.myApproval {
  width: 452px;
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;

  .date-footer{
    font-size: 14px;
    font-weight: 400;
    color: #3377FF;
    display: flex;

    div{
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .item-main{
    font-size: 14px;
    margin-top: 16px;
    .item-status{
      text-align: center;
    }
    .approval-header {
      display: flex;
      font-weight: 700;
      color: #303133;
      height: 36px;
      line-height: 36px;
      background: #EAF2FE;
      .time-date{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
          margin-left: 4px;
          width: 14px;
          height: 14px;
        }
      }
      .header-item{
        flex: 1;
        .ant-picker-footer{
          border-bottom: none;
        }
        .ant-picker-panel{
          .ant-picker-body{
            border-right: 1px solid #E9EBEF;
          }
          
        }
        .ant-picker-dropdown{
          top: -260px !important;
          left: 110px !important;
        }
        .ant-picker-date-panel .ant-picker-body{
          padding: 16px 18px;
        }
        .ant-picker-header{
          border-bottom: 1px solid #F2F5FC;
        }
        .ant-picker-range-arrow{
          display: none;
        }
        .ant-picker-footer-extra{
          padding: 0 !important;
          line-height: 39px;
          text-align: left;
        }
        // 日期样式修改
        .ant-picker-panels{
          .ant-picker-cell-in-view{
            color: #333333;
          }
          .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
            content: '';
            background: #EAF2FE !important;
          }

          .ant-picker-cell-in-view.ant-picker-cell-range-start, .ant-picker-cell-in-view.ant-picker-cell-range-end{
            font-size: 14px;
            color: #FFFFFF;
            .ant-picker-cell-inner{
              background: #3377FF;
              color: #FFFFFF !important;
            }
          }
          .ant-picker-cell-today{
            .ant-picker-cell-inner{
              font-size: 14px;
              font-weight: 500;
              color: #3377FF;
            }
            .ant-picker-cell-disabled::before{
              background: #3377FF !important;
            }
          }
          &>div:last-child{
            .ant-picker-cell-today .ant-picker-cell-inner{
              color: #C0C4CC;
            }
          }
        }
      }
      .item-status{
        text-align: center;
      }
    }
    .pL32{
      box-sizing: border-box;
      padding-left: 32px;
    }
    .approval-content{
      .empty-main{
        padding: 12px 0;
        img {
          width: 122px;
          height: 100px;
          margin: 0 auto;
          display: block;
        }
        .empty-txt{
          margin-top: 8px;
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          color: #909399;
          font-size: 14px;
        }
      }
      .content-item{
        display: flex;
        font-weight: 400;
        padding: 12px 0;
        border-bottom: 1px solid #E9EBEF;
        .approval-title {
          flex: 1;
        }
        .approval-deal{
          flex: 1;
          text-align: center;
          .deal-statusTxt {
            height: 24px;
            border-radius: 4px;
            line-height: 24px;
            padding: 3px 8px;
          }

        }
      }
      &>div:last-child {
        border: none !important;
      }
   
    }
  }
  .mt0{
    margin-top: 0px;
  }
  @media screen and (min-width:930px) and (max-width:1028px){
    .item-main .approval-header .header-item{
      .ant-picker-dropdown{
        top: -260px !important;
        left: 0px !important;
      }
    } 
  }
  @media screen  and (max-width:940px){
    .item-main .approval-header .header-item{
      .ant-picker-dropdown{
        top: -260px !important;
        left: -50px !important;
      }
    } 
  }

  // 设置选择框样式
  .sponsor-time{
    // display: none;
    padding: 0;
    .ant-picker-input, .ant-picker-range-separator,  .ant-picker-range-separator , .ant-picker-active-bar, .ant-picker-suffix{
      display: none !important;
      // background: transparent;
    }
  }
  // nav样式
  .tabs-main{
    color: #606266;
    
    .ant-tabs-tab{
      padding: 12px 0px;
      min-width: 88px;
      justify-content: center;
    }
    .ant-tabs-tab:hover{
      color: #498BFD;
    }
    .ant-tabs-nav{
      margin: 0 0 12px 0;
    }
    .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 24px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      color: #3377FF;
      font-weight: 500;
      text-shadow: none;
    }
    .ant-tabs-ink-bar{
      background: #3377FF;
    }
  }
  // 分页样式
  .pageination-main{
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    li{
      font-size: 14px  !important;
      height: 22px !important;
      line-height: 20px !important;
      min-width: 22px !important;
      margin-left: 8px !important;
      .nofollow{
        padding: 0 7px;
      }
    }
    &li:first-child{
      margin-left: 0 !important;
    }
  }
}

