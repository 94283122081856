.contract-mainPCV2110{
  position: relative;
  height: 178px;
  padding-bottom: 10px;
  // width: 306px;
  .title-tips{
    font-size: 14px;
    color: #1D1E22;
    line-height: 22px;
    .link{
      color: #216AFD;
    }
  }
  .mt8{
    margin-top: 8px;
  }
  .introduce-detail{
    position: absolute;
    left: 8px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #000000;
    flex-direction: column;
    align-items: center;
    width: 163px;
    padding: 16px 0;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E6EAEC;
    margin: 0 auto;
    margin-top: 8px;
    .qrCode{
      width: 118px;
      height: 118px;
    }
  }
}