.v2400-RemindSet{
  // width: 432px;
  // margin-top: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  .remindList{
    .item-main{
      display: flex;
      // align-items: center;
      align-items: flex-start;
      flex-wrap: wrap;
      .content-item{
        margin-top: 12px;
        width: 198px;
        // height: 58px;
        border-radius: 6px;
        border: 1px solid #E9EBEF;
        box-sizing: content-box;
        padding: 14px 20px;
        .set-main{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          color: #333333;

          .name-tips{
            display: flex;
            align-items: center;
          }

          .changeSwitch{
            margin-left: 5px;
            .ant-switch{
              background: #D0D6DA;
            }
            .ant-switch-checked{
              background: #216AFD;
            }

            .ant-switch{
              width: 28px;
              height: 16px;
              min-width: 28px;
              .ant-switch-handle{
                width: 12px;
                height: 12px;
              }
            }

            .ant-switch-checked{
              .ant-switch-handle{
                left: calc(100% - 14px);
              }
            }

          }

          .ML4{
            margin-left: 4px;
          }

        }
        
        .budgetTipTxt{
          margin-top: 4px;
          margin-bottom: -8px;
          color: #3377FF;
          line-height: 18px;
          cursor: pointer;

          .notSetTxt{
            color: #C0C4CC;
          }
        }
      }
      &>div:nth-child(2n){
        margin-left: 8px;
      }
    }

  }
}