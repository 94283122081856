.MobileStaff {
    display: flex;
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    .link {
        color: #3377FF;
        cursor: pointer;
    }
    .no-link {
        color: #909399;
        margin-left: 10px;
    }
}