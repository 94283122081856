.recommendMobileMain{
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  max-width: calc(540rem/75);
  min-width: calc(384rem/75);
  .info-list{
    // margin-top: calc(16rem/75);
    .list-item{
      cursor: pointer;
      margin-top: calc(12rem/75);
      font-size: calc(24rem/75);
      color: #3377FF;
      line-height: calc(34rem/75);
    }
    .list-item_circle{
      margin: calc(12rem/75) calc(16rem/75) 0 0;
      text-align: center;
      height: calc(48rem/75);
      line-height: calc(48rem/75);
      padding: 0 calc(36rem/75);
      border-radius: calc(24rem/75);
      position: relative;
      border: calc(2rem/75) solid #3377FF;
      box-sizing: content-box;
      margin-bottom: 0;
    }
  }
  .info-list_circle{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

}