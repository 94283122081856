.v2110TaxDetailMobile {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  position: relative;

  // 列表样式
  .billList{
    .content-item{
      box-sizing: content-box;
      margin-top: calc(20rem/75);
      border-radius: calc(16rem/75);
      border: calc(2rem/75) solid #E9EBEF;

      .ml5{
        margin: 0 calc(5rem/75);
      }

      .item-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: calc(16rem/75) calc(24rem/75);
        background: #EAF2FE;
        border-radius: calc(16rem/75) calc(16rem/75) 0px 0px;
        font-size: calc(26rem/75);
        box-sizing: content-box;
        .customer-name{
          font-weight: 500;
          color: #333333;
        }
        .number-date{
          font-size:  calc(24rem/75);
          color: #909399;
          word-break: break-all;
        }
      }
      .item-bill{
        margin-top: calc(24rem/75);
        box-sizing: content-box;
        padding: 0 calc(24rem/75) ;
        position: relative;
        padding-bottom: calc(16rem/75);
        word-break: break-all;
        .bottom-line{
          // margin: 0 calc(24rem/75);
          border-bottom: calc(2rem/75) solid #E9EBEF;
          margin-top: calc(16rem/75);
        }
        .flex{
          display: flex;
          justify-content: space-between;
          div{
            flex: 1;
          }
        }
        .mt4{
          margin-top: calc(4rem/75);
        }
        .bill-title{
          font-size: calc(26rem/75);
          font-weight: 500;
          color: #333333;
          .img-txt{
            display: flex;
            align-items: center;
            font-size: calc(24rem/75);
            img{
              width: calc(32rem/75);
              height: calc(32rem/75);
              margin-right: calc(8rem/75);
            }
          }
        }


        .txt-des{
          color: #909399;
          font-size: calc(24rem/75);
          font-weight: 400;
        }
        .txtAlignR{
          text-align: right;
        }
      }
      .item-bill_pt56{
        padding-bottom: calc(56rem/75);
      }

    }
    .ml24{
      margin: calc(20rem/75) calc(24rem/75) 0;
    }


  }

  .v1124_filterBottomMobile{
    .left{
      bottom: inherit !important;
    }
    .ismore {
      .more{    
        left: 50%  !important;
        transform: translateX(-50%) !important;
      }
    }

  }

}

