.myApprovalMobile-main {
  border-radius: calc(10rem/75);
  font-size: calc(24rem/75);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  font-size: calc(24rem/75);
  .tabs-main{
    margin-top: calc(16rem/75);
    .Tabs-nav{
      margin-bottom: 0;
      border-bottom: calc(1rem/75) solid #E9EBEF;
      .Tabs-navPointer{
        border-radius: 0;
        height: calc(4rem/75);
        box-shadow: none;
        height: calc(4rem/75);
        border-radius: calc(2rem/75);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: transparent;
        &::after{
          content: '';
          width: calc(144rem/75) !important;
          margin: 0 auto;
          background: #3377FF;
          height: calc(4rem/75);
        }
      }
      
      .Tabs-navItem{
        .Tabs-navLink{
          background: transparent;
          padding: calc(8rem/75) 0;
          font-size: calc(26rem/75)  !important;
          color: #606266 !important;
          border-radius: 0  !important;
        }
        .Tabs-navLink.active{
          font-weight: 500;
          color: #3377FF !important;
        }
      }
    }

  }
  .pL24{
    padding-left: calc(24rem/75);
  }
  .item-main{
    margin-top: calc(16rem/75);
    // padding: 0 calc(8rem/75) 0 calc(8rem/75);
    box-sizing: border-box;
    &>div:last-child {
      border-bottom: none !important;
    }
    .approval-header {
      display: flex;
      font-weight: 700;
      color: #303133;
      // height: calc(64rem/75);
      padding: calc(16rem/75) 0;
      background: #EAF2FE;
      font-size: calc(26rem/75);

      .header-item{
        flex: 1;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .time-date{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img{
            margin-left: calc(4rem/75);
            width: calc(28rem/75);
            height: calc(28rem/75);
            transform: scale(.8);
          }
        }

      }
      .item-status{
        justify-content: center;
      }

    }

    .content-item{
      color: #303133;
      display: flex;
      font-weight: 400;
      // height: calc(64rem/75);
      padding: calc(10rem/75) 0;
      border-bottom: 1px solid #E9EBEF;
      align-items: center;
      .approval-title {
        flex: 1;
      }
      .approval-deal{
        flex: 1;
        text-align: center;
        .deal-statusTxt {
          text-align: center;
          padding: calc(5rem/75) calc(12rem/75) calc(6rem/75);
          border-radius: calc(6rem/75);
        }
      }
    }

    .empty-main{
      margin-top: calc(32rem/75);
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: calc(180rem/75);
        height: calc(148rem/75);
      }
      .empty-txt{
        text-align: center;
        margin-top: calc(8rem/75);
        font-size: calc(24rem/75);
        font-weight: 400;
        color: #909399;
      }

    }


    .MLR8{
      margin: 0 calc(8rem/75);
    }
  }
  .mobile-filter-bottom {
    position: absolute;
    bottom: calc(-55rem/75);
    height: calc(90rem/75);
    width: 100%;
    .flex {
        display: flex;
    }
    img {
        vertical-align: top;
    }
    .align-item {
        align-items: center;
    }
    .filter {
        height: calc(32rem/75);
        background: #FFFFFF;
        filter: blur(calc(10rem/75));
    }
    .left {
        margin-right: calc(16rem/75);
        position: absolute;
        bottom: calc(18rem/75);
    }
    .pack-up {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        margin-right: calc(8rem/75);
    }
    .ismore {
        height: calc(48rem/75);
        align-items: center;
        .more {
            width: calc(136rem/75);
            height: calc(48rem/75);
            border-radius: calc(24rem/75);
            border: calc(2rem/75) solid #DCDFE6;
            font-size: calc(24rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: calc(227rem/75);
            .loading {
              width: calc(40rem/75);
              animation: identifier 1s linear infinite;
            }
        }
    }
    .pick-style {
      width: calc(120rem/75);
      height: calc(32rem/75);
      justify-content: flex-end;
    }
  }
}


    
    // 修改样式 日期组件
    .mobile_v1_1_23-popup-container{
      .adm-calendar-arrow-button, .adm-calendar-arrow-button-right{
        color: #3377FF !important;
      }
      
      .popupDefaultHeight{
        padding-bottom: calc(10px + constant(safe-area-inset-bottom));
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
        min-height: 55vh;
      }
      .confirm-date{
        display: flex;
        justify-content: space-between;
        height: calc(70rem/75);
        line-height: calc(70rem/75);
        font-size: calc(28rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        padding: 0 calc(25rem/75);
        box-sizing: content-box;
        .txt_3377FF{
          color: #3377FF;
        }
      }
      .adm-calendar-cell{
        color: #333333;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      .adm-calendar-cell-out{
        color: #C0C4CC  !important;
        font-weight: 400 !important;
      }
      .adm-calendar-cell-today{
        border: 1px solid #3377FF;
        color: #3377FF !important;
        border-radius: 4px;
        border-radius: 4px;
      }
      .adm-calendar-cell-selected{
        color: #333333 !important;
        font-weight: 400;
        background: #EAF2FE !important;
      }
      .adm-calendar-cell-selected-begin, .adm-calendar-cell-selected-end{
        background: #3377FF !important;
        color: #FFFFFF !important;
      }

    }