.QRcode {
    img {
        width: 100%;
        height: 100%;
        vertical-align: top;
    }
    .mask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 99;
    }
    .close {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
    .QRcode-content {
        width: 550px;
        height: 400px;
        padding: 40px 20px 20px;
        color: #333333;
        background-color: #fff;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        text-align: center;
        line-height: 34px;
        box-sizing: border-box;
        border-radius: 8px;
        .tips-bold{
            font-size: 20px;
            font-weight: bold;
        }
        .tips-normal{
            font-size: 14px;
        }
    }
}