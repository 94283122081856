.FirstSentence {
    cursor: pointer;
    display: flex;
    align-items: center;
    .img {
        width: 12px; 
        height: 12px; 
        margin-right: 4px; 
        line-height: 12px;
    }
    .font {
        font-size: 12px;
        line-height: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
    }
    &:hover {
        .font {
            font-size: 12px;
            line-height: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #3377FF !important;
        }
        svg path {
            fill: #3377FF;
        }
    }
}