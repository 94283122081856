.mobileRate {
    // height: calc(200rem/75);
    border-radius: calc(8rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    padding: calc(20rem/75);
    box-sizing: border-box;
    font-family: PingFangSC-Medium, PingFang SC;
    margin-top: calc(12rem/75);
    .min-width {
        width: calc(203rem/75) !important;
    }
    .max-width {
        width: calc(226rem/75);
    }
    .max-width2 {
        width: calc(196rem/75);
    }
    .step {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: calc(18rem/75);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        .line {
            width: calc(186rem/75);
            height: 1px;
            background: #3377FF;
            margin: 0 calc(4rem/75);
        }
        .line2 {
            width: calc(156rem/75);
            height: 1px;
            background: #3377FF;
            margin: 0 calc(4rem/75);
        }
        .width {
            width: calc(165rem/75);
        }
        .step-two {
            width: calc(32rem/75);
            height: calc(32rem/75);
            border-radius: 50%;
            .num {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #3377FF;
                text-align: center;
                line-height: calc(32rem/75);
            }
        }
        .step-three {
            width: calc(32rem/75);
            height: calc(32rem/75);
            border-radius: 50%;
            .num {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #3377FF;
                text-align: center;
                line-height: calc(32rem/75);
            }
        }
        .noactive {
            background: rgba(220, 223, 230, 1);
        }
        .noactiveIcon {
            width: calc(32rem/75);
            height: calc(32rem/75);
            border-radius: 50%;
            .num {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: rgba(220, 223, 230, 1);
                text-align: center;
                line-height: calc(32rem/75);
            }
        }
    }
    .tijiao {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: calc(33rem/75);
    }
    .name {
        display: flex;
        font-size: calc(20rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C0C4CC;
        line-height: calc(28rem/75);
        margin-top: calc(8rem/75);
    }
    & > .rate :nth-last-child(2) {
        margin: 0;
    }
}