.v2300-ZKGJProjectBudgetRemind{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 580px;
  .remainingMoneyTxt{
    color: #FFAA00;
  }
  .mr3{
    margin-right: 3px;
  }
  .ml5{
    margin: 0 5px;
  }
  .v2300ContractTipBox{
    max-width: 380px !important;
  }

  .editebudgetTxt{
    cursor: pointer;
    margin-left: 5px;
    color: rgb(51, 119, 255); 
  }

  .setBudgetMain{
    color: rgb(51, 119, 255); 
    cursor: pointer;
  }
  .budgetInputV2300PC{
    width: 120px;
    height: 20px;
    background: #F3F6FA;
    border-radius: 4px;
    border: none;
    border-bottom: 1px solid #D0D6DA;
    font-size: 12px;
    padding: 5px 10px;
    color: #333333;
  }

  .remindList{
    margin: 12px 0;
    padding: 12px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    font-size: 12px;
    // position: relative;
    .title-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .title{
        font-weight: 700;
        color: #303133;
      }
      .budgetFlex{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .list-money{
      display: flex;
      color: #333333;
      margin-top: 8px;
      .pre-money{
        width: 260px;
        min-width: 260px;
        margin-right: 5px;
      }
      .flex1{
        flex: 1;
      }
    }
  }
  .remindMain{
    &>div:last-child{
      margin-bottom: 0;
    }
  }

}