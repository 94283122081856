.text { 
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    max-width: 588px;
    box-sizing: content-box; // antd样式

    .title_show_logout{
      color:#C9CED1;
      font-size: 12px;
    }

    .button999 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3377FF;
        line-height: 18px;
        margin-top: 8px;
    }
    .footer {
        margin-top: 8px;
        color: #3377FF;
        .line {
            width: 100%;
            height: 1px;
            background: #E9EBEF;
        }
    }
}
@keyframes identifier {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.text-left {
    background: #FFFFFF;
    color: #333333;
    padding: 12px 10px 12px 10px;
    margin-left: 8px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #FFFFFF;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        background-color: transparent;
        transform: rotate(225deg);
        position: absolute;
        left: -5px;
        top: 0;
    }
}
.text-right {
    background: #3377FF;
    color: #FFFFFF;
    padding: 12px 10px 12px 10px;
    margin-right: 8px;
    position: relative;
    word-break: break-all;
    word-wrap: break-word;
    .header {
        &::selection {
            background:#A7C5FF;
        }
        &::-moz-selection {
            background:#A7C5FF;
        }
        &::-webkit-selection {
            background:#A7C5FF;
        }
    }
    .text-right-wrong {
        width: 16px;
        height: 16px;
        background: url('../images/icon-warning.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -28px;
        cursor: pointer;
    }
    .text-right-loading {
        width: 16px;
        height: 16px;
        background: url('../images/loading.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -28px;
        cursor: pointer;
        animation: identifier 1s linear infinite;
    }
    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #3377FF;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        transform: rotate(135deg);
        position: absolute;
        right: -5px;
        top: 3px;
        
    }
}
.V2300QuickQueryPC{
  margin-left: 0;
  background: transparent;
  margin-top: -15px;
  &::before{
    content: '';
    display: none;
  }
}

// PC 端tips
.V2300PCTooltipMain{
  // width: 14px;
  // height: 14px;
  // padding-left: 13px;
  position: relative;

  .tipsImg{
    width: 14px;
    height: 14px;
  }
  .ant-tooltip-inner{
    // background: rgba(0,0,0, 0.7);
    color: #fff;
    font-size: 12px;
    // box-shadow: none !important;
  }
  .ant-tooltip-placement-bottomRight, .ant-tooltip-placement-topRight{
    .ant-tooltip-arrow{
      // right: -1.5px !important;
    }
  }
  .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-topLeft{
    .ant-tooltip-arrow{
      // left: -1.5px  !important;
      // right: unset !important;
    }

  }
}

// 移动端tips
.V2300MobileTooltipMain{
  position: relative;
  .adm-popover{
    max-width: calc(500rem/75) !important;
  }
  .adm-popover-inner{
    background: #000000bf;
    color: #fff;
    font-size: calc(20rem/75);
    max-width: calc(500rem/75) !important;
    width: calc(500rem/75) !important;
  }
  .adm-popover-arrow{
    color: #000000bf !important;
  }
  .tipsImg{
    width: calc(28rem/75);
    height: calc(28rem/75);
  }
 
}

.V2300QuickQueryMobile{
  background: transparent !important;
  // margin-left: calc(-4rem/75) !important;
  margin-left: -4px !important;
  // padding: 0 !important;
  margin-top: calc(-11rem/75);
  height: calc(484rem/75);
  // max-width: calc(680rem/75) !important;
  // background: red !important;
  // overflow: hidden;
  &::before{
    content: ''  !important;
    display: none !important;
  }
}
.v2300ChatFeedBack{
  position: absolute;
  right: -35px;
  bottom: 0;
  .goodImg{
    height: 24px;
  }
  .mt3{
    margin-top: 3px;
  }
  img{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .img-up{
    transform: rotateZ(-180deg);
  }

}

.v2300MobileChatFeedBack{
  position: absolute;
  right: calc(-58rem/75);
  bottom: 0;
  .goodImg{
    height: calc(48rem/75);
  }
  img{
    width: calc(48rem/75);
    height: calc(48rem/75);
    cursor: pointer;
  }
  .img-up{
    transform: rotateZ(-180deg);
  }
  .mt3{
    margin-top: calc(6rem/75);
  }
}

.mobile {
    font-size: calc(28rem/75);
    line-height: calc(38rem/75);
    border-radius: calc(15rem/75);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    // max-width: calc(540rem/75);
    max-width: calc(608rem/75);
    // max-width: calc(608rem/75);
    box-sizing: content-box; // antd 覆盖
    // overflow: hidden;
    .title_show_logout{
      color:#C9CED1;
      font-size: calc(24rem/75);
    }
    .button999 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3377FF;
        line-height: 18px;
        margin-top: 8px;
    }
    footer {
        margin-top: calc(12rem/75);
        width: 100%;
        height: calc(58rem/75);
        .line {
            width: 100%;
            height: 1px;
            background: #E9EBEF;
        }
        .look-more {
            color: #3377FF;
            font-size: calc(28rem/75);
            line-height: calc(58rem/75);
        }
    }
}
.mobile-left {
    background: #FFFFFF;
    color: #333333;
    // padding: calc(12rem/75) calc(16rem/75);
    padding: 10px 8px 10px 8px;
    margin-left: 6px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #FFFFFF;
        border-bottom: 5px solid transparent;
        border-left:5px solid transparent;
        border-right:5px solid transparent;
        background-color: transparent;
        transform: rotate(225deg);
        position: absolute;
        left: -5px;
        top: 0;
    }
}
.mobile-right {
    background: #3377FF;
    color: #FFFFFF;
    padding: 10px 8px 10px 8px;
    margin-right: 6px;
    position: relative;
    word-break: break-all;
    word-wrap: break-word;
    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #3377FF;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        background-color: transparent;
        transform: rotate(135deg);
        position: absolute;
        right: -5px;
        top: 0;
        
    }
    .text-right-wrong {
        width: 16px;
        height: 16px;
        background: url('../images/icon-warning.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -20px;
        cursor: pointer;
    }
    .text-right-loading {
        width: 16px;
        height: 16px;
        background: url('../images/loading.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -20px;
        cursor: pointer;
        animation: identifier 1s linear infinite;
    }
}