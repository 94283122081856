.mobileV2300ZKGJProjectBudgetRemind {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  // margin-top: calc(4rem/75);

  .remainingMoneyTxt{
    color: #FFAA00;
  }
  
  .v2300MObileContractTipBox{
    max-width: calc(540rem/75);
    .ant-tooltip-inner{
      font-size: calc(24rem/75);
    }
  }

  .setBudgetTxt{
    color: rgb(51, 119, 255); 
    cursor: pointer;
  }
  .setBudgetTxtIcon{
    color: rgb(51, 119, 255); 
    cursor: pointer;
    margin-left: calc(10rem/75);
  }

  .setBudgetMain{
    color: rgb(51, 119, 255); 
    cursor: pointer;
    .budgetInputV2300Mobile{
      width: calc(300rem/75);
      min-height: calc(20rem/75);
      .adm-input-element{
        font-size: calc(24rem/75);
        color: #333333;
        border-bottom: calc(2rem/75) solid #E9EBEF;
        height: calc(20rem/75);
        line-height: calc(64rem/75);
      }
    }
  }

  .remindList{
    margin: calc(20rem/75) 0;
    border-radius: calc(16rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    padding: calc(24rem/75);
    text-align: left;
    box-sizing: content-box;
    word-break: break-all;
    position: relative;
    .title-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list-title{
        font-size: calc(24rem/75);
        font-weight: 700;
        color: #333333;
        margin-right: calc(10rem/75);
      }
      .contractTipImg{
        width: calc(28rem/75);;
        height: calc(28rem/75);;
        right: calc(24rem/75);;
        top: calc(24rem/75);;
        cursor: pointer;
        z-index: 5;
      }
    }

    .list-money{
      font-size: calc(24rem/75);
      .pre-money{
        color: #333333;
        margin-top: calc(16rem/75);
        .pre-money_label{
          color: #909399;
        }
      }
      .budgerFlex{
        display: flex;
        align-items: center;
      }
      .mt8{
        margin-top: calc(8rem/75);
      }
    }
  }

  .list-main{
    &>div:last-child{
      margin-bottom: 0;
    }
  }
}

