.contract-mainMobileV2110{
  // width: calc(540rem/75);
  position: relative;
  height: calc(450rem/75);
  padding-bottom: calc(10rem/75);
  .title-tips{
    font-size: calc(24rem/75);
    color: #1D1E22;
    // margin-top: calc(12rem/75);
    .link{
      color: #216AFD;
    }
  }
  .mt8{
    margin-top: calc(8rem/75);
  }
  .introduce-detail{
    position: absolute;
    left: 6px;
    display: flex;
    justify-content: center;
    font-size: calc(24rem/75);
    color: #000000;
    flex-direction: column;
    align-items: center;
    width: calc(400rem/75);
    padding: calc(16rem/75) 0;
    background: #FFFFFF;
    border-radius: calc(16rem/75);
    border: calc(2rem/75) solid #E6EAEC;
    margin: 0 auto;
    margin-top: calc(12rem/75);
    .qrCode{
      width: calc(360rem/75);
      height: calc(360rem/75);
    }
  }
}