.chatBox {
    width: 100vw;
    height: 100vh;
    .nopadding {
        padding: 0 !important;
    }
    .unfold {
        width: 12px;
        height: 34px;
        background-color: red;
        transform: translateY(50%);
        position: fixed;
        top: 50%;
        right: 0px;
        background: url('../images/unfold.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transform: translateY(-17px);
        &:hover {
            background: url('../images/select_fold.png') no-repeat;
            background-size: 100% 100%;
            transform: rotate(180deg) translateY(17px);  
        }
    }
    font-family: PingFangSC-Light, PingFang SC;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    #upload {
        display: none;
    }
    //遮罩层
    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;
        .short-time {
            div:nth-child(1) {
                width: 108px;
                height: 176px;
                position: absolute;
                top: 158px;
                left: 244px;
            }     
            div:nth-child(2) {
                width: 282px;
                height: 54px;
                text-align: center;
                line-height: 54px;
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                background: url('../images/dialog_box.png') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 221px;
                left: 364px;
            }
        }
        .unidentification {
            div:nth-child(1) {
                width: 132px;
                position: absolute;
                top: 158px;
                left: 236px;
            }     
            div:nth-child(2) {
                width: 383px;
                height: 54px;
                text-align: center;
                line-height: 54px;
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                background: url('../images/dialog_box.png') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 221px;
                left: 380px;
            }
        }
        .voiceing {
            #trans-content {
                width: 600px;
                height: 44px;
                font-size: 16px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #FFFFFF;
                text-align: center;
                overflow: auto;
                &::-webkit-scrollbar {
                    background: transparent;
                    border-radius: 3px;
                    width: 6px;
                    padding-bottom: 50px;
                }
                &::-webkit-scrollbar-thumb{
                    border-radius: 7px;
                    background-color: transparent;
                }
            }
            &>div:nth-child(1) {
                height: 560px;
                width: 400px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                .maikefengPC {
                    width: 344px;
                    height: 344px;
                }
                &>div:nth-child(2) {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                &>div:nth-child(3) {
                    font-size: 16px;
                    margin-top: 8px;
                    line-height: 22px;
                    font-weight: 300;
                    color: #FFFFFF;
                }
                .maikefeng-buttons {
                    width: 228px;
                    display: flex;
                    justify-content: space-between;
                    button {
                        width: 96px;
                        height: 32px;
                        border-radius: 28px;
                        border: 1px solid #FFFFFF;
                        background-color: transparent;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 20px;
                        margin-top: 55px;
                        text-align: center;
                        cursor: pointer;
                        outline: 0;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                    
                }
            }

        }
    }
    // 左边聊天框
    .ChatApp {
        flex: 1;
        .Navbar-title {
            font-size: 16px !important;
        }
        .Navbar{
            background: linear-gradient(270deg, #7BA7FF 0%, #3377FF 100%);
            border-radius: 4px 0px 0px 0px;
        }
        .renderBeforeMessageList {
            margin: 0 auto;
            margin-top: 12px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
            color: #C0C4CC;
            line-height: 18px;
        }
    }
    // 右边侧边栏
    .silder {
        width: 300px;
        padding: 0 20px;
        box-sizing: border-box;
    }
}

// 动画_样式
@keyframes v1123_relative_identifier {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
// 1.1.23样式汇总
.v1123_relative{
  position: relative;
  .mask {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 30px;
      right: 0;
      background-color: rgba(255, 255, 255, .8);
  }
  .loading {
      width: 32px;
      height: 32px;
      position: absolute;
      left: calc(50% - 16px);
      top: calc(50% - 30px);
      animation: v1123_relative_identifier 1s linear infinite;
      z-index: 55;
  }
}

// 1.1.24PC分页样式
.v1124_pageinationPC{
  margin-top: 8px  !important;
  display: flex;
  justify-content: flex-end;
  li{
    font-size: 12px  !important;
    height: 22px !important;
    line-height: 20px !important;
    min-width: 22px !important;
    margin-left: 8px !important;
    .nofollow{
      padding: 0 7px;
    }
  }
  &li:first-child{
    margin-left: 0 !important;
  }
}

// v1.1.24移动端 查看更多样式
.v1124_filterBottomMobile{
  position: absolute;
  bottom: calc(-55rem/75);
  height: calc(90rem/75);
  box-sizing: content-box;
  width: 100%;
  .flex {
      display: flex;
  }
  img {
      vertical-align: top;
  }
  .align-item {
      align-items: center;
  }
  .filter {
      height: calc(32rem/75);
      background: #FFFFFF;
      filter: blur(calc(10rem/75));
  }
  .left {
      margin-right: calc(16rem/75);
      position: absolute;
      bottom: calc(18rem/75);
  }
  .pack-up {
      font-size: calc(24rem/75);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      margin-right: calc(8rem/75);
  }
  .ismore {
      height: calc(48rem/75);
      align-items: center;
      .more {
          width: calc(136rem/75);
          height: calc(48rem/75);
          border-radius: calc(24rem/75);
          border: calc(2rem/75) solid #DCDFE6;
          font-size: calc(24rem/75);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: calc(227rem/75);
          .loading {
            width: calc(40rem/75);
            animation: identifier 1s linear infinite;
          }
      }
  }
  .pick-style {
    width: calc(120rem/75);
    height: calc(32rem/75);
    justify-content: flex-end;
  }
}

// v2.2.0移动端 查看更多样式
.v2200_filterBottomMobile{
  .left{
    bottom: inherit !important;
  }
  .ismore {
    .more{    
      left: 50%  !important;
      transform: translateX(-50%) !important;
    }
  }

}

.v2210_moneyUnitMR3 {
  margin-right: 3px;
}

.v2210_moneyUnitMobileMR3 {
  margin-right: calc(3rem/75);
}

.none {
    display: none;
}
.img {
    width: 100%;
}
@keyframes identifier {
    0% {
        top: 0;
    }
    50% {
        top: 12px;
    }
    100% {
        top: 0;
    }
}
.trans {
    width: 66px;
    height: 36px;
    border-radius: 18px;
    background: #3377FF;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 16px;
    .a01 {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #98BAFF;
        position: relative;
        top: 0;
        animation: identifier 0.9s linear infinite;
    }
    .a01:first-child {
        opacity: 0.9;
    }
    .a01:nth-child(2) {
        opacity: 0.3;
        animation-delay: .225s;
    }
    .a01:last-child {
        opacity: 0.6;
        animation-delay: .45s;
    }

}
.select-none {
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/   
    -webkit-user-select:none; /*webkit浏览器*/   
    -khtml-user-select:none; /*早期浏览器*/   
    -moz-user-select:none;/*火狐*/   
    -ms-user-select:none; /*IE10*/   
    user-select:none;
}
.pc-knowledge {
    width: 582px;
    height: 269px;
}
.mobile-knowledge {
    width: calc(638rem/75);
    height: calc(295rem/75);
}
* {
    margin: 0;
    padding: 0;
}

#__vconsole .vc-switch{
  bottom: 119px !important;
}