.carouselMainV2300{
  height: 244px;
  display: flex;
  align-items: center;
  background: transparent;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  margin-left: -11px;

  .carousel-box{
    background: #FFFFFF;
    display: flex;
    align-items: center;
    // margin: 0 12px;
    .ct-L{
      margin-right: 28px;
      // margin-left: 16px;
      img{
        width: 58px;
        height: 58px;
        margin-left: 16px;
      }
    }
  }
  // .carouse-arrowImg{
  //   width: 36px;
  //   height: 36px;
  //   cursor: pointer;
  // }
  .carouse-L{
    width: 48px;
    // margin-right: 12px;
    .carouse-arrowImg{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }
  .carouse-ct{
    width: 363px !important;
    height: 244px;
    background: #FFFFFF;
    border-radius: 4px;
    // margin: 0 12px;
    box-shadow: 0px 0px 10px 1px rgba(0, 43, 128, 0.08);
    display: flex !important;
    box-sizing: content-box;
    align-items: center;
    // padding: 16px;
    // padding: 0 16px;
    margin-right: 16px;
    font-size: 12px !important;
    line-height: 1 !important;
    .ct-R{
      
      &>div:first-child{
        border-top: none;
      }
    }
  }
  .query-content{
    border-top: 1px solid #E9EBEF;
    .queryH64{
      margin: 4px 0;
      width: 346px;
      height: 64px;
      display: flex;
      align-items: center;
    }
    .bill-line{
      width: 1px;
      height: 64px;
    }
    
    .bill-title{
      width: 70px;
      display: flex;
      align-items: center;
      font-weight: 700;
      .bill-img{
        width: 14px;
        height: 14px;
        margin-right: 2px;
      }
    }

    .bill-content{
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 16px;
      height: 64px;

      .recommend-txt{
        height: 64px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #3377FF;
        line-height: 20px;
        div{
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          text-overflow: ellipsis;
        }
    
      }

      .refresh-main{
        color: #909399;
        cursor: pointer;
        min-width: 60px;
        display: flex;
        justify-content: flex-end;
        .refresh-img{
          display: none;
        }
        .refresh-Default{
          display: block;
        }
        img{
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
        &:hover{
          color: #3377FF;
          .refresh-img{
            display: block;
          }
          .refresh-Default{
            display: none;
          }
        }
      }
    }
  }
  .carousel-pannel{
    // width: 464px;
    width: 363px;
    height: 244px;
    background: #FFFFFF;
    padding: 0;
  }
  .carouse-R{
    width: 48px;
    display: flex;
    justify-content: flex-end;
    .carouse-arrowImg{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }

}