.rate-list {
    .rate {
        width: 546px;
        height: 138px;
        border-radius: 8px;
        border: 1px solid #DCDFE6;
        padding: 20px;
        box-sizing: border-box;
        font-family: PingFangSC-Medium, PingFang SC;
        margin-top: 12px;
        .step {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            .line {
                width: 162px;
                height: 1px;
                background: #3377FF;
                margin: 0 4px;
            }
            .step-two {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                .num {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: #3377FF;
                    text-align: center;
                    line-height: 32px;
                }
                
            }
            .noactive {
                background: rgba(220, 223, 230, 1);
            }
            .noactiveIcon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                .num {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: rgba(220, 223, 230, 1);
                    text-align: center;
                    line-height: 32px;
                }
            }
        }
        .tijiao {
            font-size: calc(24rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: calc(33rem/75);
        }
        .name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C0C4CC;
            line-height: 16px;
            display: flex;
            margin-top: 10px;
        }    
    }
    & > .rate :nth-last-child(2) {
        margin: 0;
    }
}
.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(192, 196, 204, 1);
    line-height: 18px;
    cursor: pointer;
    margin-top: 12px;
    .color {
        color: rgba(51, 119, 255, 1);
    }
    .disabled {
        cursor: not-allowed;
    }
}
