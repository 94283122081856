.MobileInquiryContent{
  font-family: PingFang SC-Regular, PingFang SC;
  max-width: calc(580rem/75);
  .item-main{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    font-size: calc(24rem/75);
    font-weight: 400;
    color: #3377FF;
    .inquiry-item{
      margin: calc(12rem/75) calc(16rem/75) 0 0;
      text-align: center;
      height: calc(48rem/75);
      line-height: calc(48rem/75);
      padding: 0 calc(36rem/75);
      // padding: calc(7rem/75) calc(36rem/75);
      border-radius: calc(24rem/75);
      position: relative;
      border: calc(2rem/75) solid #3377FF;
      box-sizing: content-box;
    }
    &>div:first-child{
      margin-left: 0px;
    }
  }
}