.v2110MobileResminSet{
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  width: calc(540rem/75);
  .remindList{
    .item-main{
      .content-item{
        margin-top: calc(16rem/75);
        border-radius: calc(12rem/75);
        border: calc(2rem/75) solid #E9EBEF;
        box-sizing: content-box;
        padding: calc(24rem/75) calc(40rem/75);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .changeSwitch{
          margin-left: calc(5rem/75);
          .ant-switch{
            width: calc(56rem/75);
            height: calc(32rem/75);
            min-width: calc(56rem/75);
            background: #D0D6DA;
            .ant-switch-handle{
              width: calc(24rem/75);
              height: calc(24rem/75);
            }
          }

          .ant-switch-checked{
            background: #216AFD;
            .ant-switch-handle{
              left: calc(100% - calc(28rem/75));
            }
          }

        }

        .set-main{
          display: flex;
          align-items: center;
          font-size: calc(26rem/75);
          color: #333333;
          justify-content: space-between;
          width: 100%;

          .name-tips{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .approval-main{
            display: flex;
            align-items: center;
          }

          .V2300MobileTooltipRemindSet{
            margin-left: calc(8rem/75);
            display: flex;
            align-items: center;
            // .ant-tooltip{
            //   left: calc(-250rem/75) !important;
            //   .ant-tooltip-arrow{
            //     left: 50% !important;
            //     margin-left: calc(-10rem/75)
            //   }
            // }
          }
        }
        
        .budgetTipTxt{
          color: #3377FF;
          cursor: pointer;
          margin-left: calc(24rem/75);

          .ml5{
            margin-left: calc(5rem/75);
          }

          .notSetTxt{
            color: #C0C4CC;
          }
        }
      }
    }

  }
}