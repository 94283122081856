.mobile-table-two {
    width: calc(540rem/75);
    background-color: #fff;
    font-size: calc(24rem/75);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    // color: #333333;
    line-height: calc(34rem/75);
    // background: url('../images/robotDialog.png') no-repeat;
    // background-size: 100% 100%;
    margin-top: calc(16rem/75);
    word-break: break-all;
    color: #3377FF;

    .header {
      font-size: calc(26rem/75);
      line-height: calc(42rem/75);
      margin-bottom: calc(24rem/75);
    }
    .margin-bottom {
        margin-bottom: calc(25rem/75);
    }
    .line {
        width: 100%;
        height: 1px;
        background: #E9EBEF;
    }
    .noline {
        width: 100%;
        height: 1px;
        background: #E9EBEF;
    }
    div {
        margin-bottom: calc(12rem/75);
    }
}