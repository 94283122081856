
.line {
    width: 194px;
    height: 115px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
    .a1::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: #3377FF;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        position: relative;
        top: 5px;
        margin-right: 4px;
        box-sizing: border-box;
    }
    .a1 {
        display: flex;
    }
    .a2::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: #FFAA00;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        position: relative;
        top: 5px;
        margin-right: 4px;
        box-sizing: border-box;
    }
    .a2 {
        display: flex;
    }
}