.next-page {
    background: #FFFFFF;
    color: #333333;
    padding: 12px 10px 12px 10px;
    margin-left: 8px;
    position: relative;
    border-radius: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
    max-width: 588px;
    .header {
        word-wrap: break-word;
        word-break: break-all;
    }
    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #FFFFFF;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        background-color: transparent;
        transform: rotate(225deg);
        position: absolute;
        left: -5px;
        top: 0;
    }
}