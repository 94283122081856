.moneyDetail {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    .flex {
        display: flex;
    }
    .money {
        width: 452px;
        box-sizing: border-box;
        border-top: 1px solid #E9EBEF;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &>.money:nth-child(3) {
        border-top: none;
    }
}