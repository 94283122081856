.InquiryContent{
  font-family: PingFang SC-Regular, PingFang SC;
  max-width: 588px;
  
  .item-main{
    box-sizing: border-box;
    // padding-left: 8px;
    display: flex;
    flex-wrap: wrap;
    .inquiry-item{
      padding: 0px 20px;
      height: 28px;
      line-height: 28px;
      // width: 128px;
      border-radius: 14px;
      border: 1px solid #3377FF;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      color: #3377FF;
      margin-right: 8px;
      margin-top: 12px;
      text-align: center;
      box-sizing: content-box;
    }
    &>div:first-child{
      // margin-left: 8px;
      margin-left: 0px;
    }
  }
}