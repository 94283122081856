.mobileAccounts {
    width: calc(540rem/75);
    border-radius: calc(8rem/75);
    min-height: calc(124rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    padding: calc(20rem/75) calc(22rem/75) calc(20rem/75) calc(12rem/75);
    font-size: calc(24rem/75);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: calc(38rem/75);
    box-sizing: border-box;
    margin-top: calc(12rem/75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .icon {
        width: calc(28rem/75);
        height: calc(28rem/75);
    }
    .card-num  {
        margin-left: calc(36rem/75);
    }
    .zhihang {
        margin-left: calc(36rem/75);
    }
    .company-name {
        word-break: break-all;
        word-wrap: break-word;
    }
    .huise {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #909399;
        line-height: calc(38rem/75);
    }
    & > div:nth-child(1) {
        display: flex;
        align-items: center;
    }
    & > div:nth-child(2) {
        display: flex;
        margin-top: calc(8rem/75);
    }
    & > div:nth-child(2) {
        margin-top: calc(8rem/75);
    }
}