.aging {
    width: 100%;
    background: #FFFFFF;
    main {
        width: 580px;
        background: #F3F6FA;
        margin-top: 8px;
        padding: 12px 20px;
        box-sizing: border-box;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
        line-height: 18px;
        // margin-bottom: 16px;
    }
    .aging > main:last-child {
        border: none;
        margin: 0;
    }
    #main1 {
        padding-bottom: 0;
        margin-top: 0;
        &>div:nth-child(2) {
            padding-bottom: 12px;
        }
    }
    #main2 {
        margin: 0;
        // margin-bottom: 5px;
    }
    .line2 {
        display: none;
    }
    .flex {
        width: 580px;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C0C4CC;
        cursor: pointer;
    }
}
@keyframes identifier {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.relative {
    position: relative;
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 30px;
        right: 0;
        background-color: rgba(255, 255, 255, .8);
    }
    .loading {
        width: 32px;
        height: 32px;
        position: absolute;
        left: calc(50% - 16px);
        top: calc(50% - 30px);
        animation: identifier 1s linear infinite;
        z-index: 55;
    }
}