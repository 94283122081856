.mobileAllAccountsList {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(26rem/75);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: calc(38rem/75);
    width: calc(540rem/75);
    .mobileAllAccounts {
        width: calc(184rem/75);
        height: calc(108rem/75);
        background: #F3F6FA;
        border-radius: calc(8rem/75);
        padding: calc(16rem/75) 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &>.mobileAllAccounts:nth-child(1) {
        margin-bottom: calc(12rem/75);
    }
    &>.mobileAllAccounts:nth-child(2) {
        margin: 0 calc(12rem/75) calc(12rem/75) calc(12rem/75);
    }
    &>.mobileAllAccounts:nth-child(5) {
        margin: 0 calc(12rem/75);
    }
}
