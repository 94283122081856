.receivableBill {
  // width: 608px;
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  // 修改tab
  .ant-tabs{
    .ant-tabs-tab{
      // height: 35px;
      // line-height: 35px;
      padding: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      width: 88px;
      justify-content: center;
      padding-bottom: 12px;
      // background: red;
    }
    .ant-tabs-nav::before{
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    }
    .ant-tabs-tab-active{
      color: #3377FF;
    }
    .ant-tabs-ink-bar{
      background: #3377FF;
      height: 2px;

    }
    .ant-tabs-nav{
      margin: 0 0 0px 0;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      color: #3377FF;
      font-weight: 700;
      text-shadow: none;
    }
  }

  .ml5{
    margin: 0 5px;
  }

  // 列表样式
  .billList{
    .item-main{
      width: 580px;
      box-sizing: content-box;
      .content-item{
        background: #FFFFFF;
        margin: 12px 0;
        border-radius: 6px;
        border: 1px solid #E9EBEF;

        .item-title {
          // padding-left: 24px;
          font-size: 12px;
          font-weight: 700;
          color: #303133;
          // height: 36px;
          // line-height: 36px;
          padding: 9px 24px;
          background: #EAF2FE;
          border-radius: 6px 6px 0px 0px;
        }
        .item-content{
          padding: 12px 24px;
          .txt-des{
            border-bottom: 1px solid #E9EBEF;
            padding-bottom: 12px;
            .des-item{
              display: flex;
              font-size: 14px;
              font-weight: 700;
              color: #303133;
              // div{
              //   flex: 1;
              //   text-align: left;
              // }
              .icon-txt{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                width: 212px;
                img{
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                }
              }
              .fee-number{
                max-width: 212px;
                width: 212px;
                word-break: break-word;
              }
              .flex1{
                flex: 1;
              }


              .des-money{
                width: 134px;
              }
            }
            .des-item_title{
              font-weight: 400;
              color: #909399;
              font-size: 12px;
            }
          }
          .txt-des_noBoder{
            border-bottom: none;
            padding-bottom: 0;
          }
          .link-list{
            margin-top: 12px;
            display: flex;
            font-size: 12px;
            .link-title{
              color: #333333;
            }
            .link-content{
              flex: 1;
              position: relative;
              .showDrownImg{
                width: 14px;
                height: 14px;
                position: absolute;
                bottom: 0;
                right: 0;
                transition: all .3s;
                cursor: pointer;
                img{
                  width: 14px;
                  height: 14px;
                }
              }
              .showDrownImg-up{
                transform: rotateX(-180deg);
                bottom: -7px;
              }
              .show-tips{
                margin-top: 8px;
                display: flex;
                align-items: center;
                img{
                  width: 12px;
                  height: 12px;
                  margin-right: 8px;
                }
                font-size: 12px;
                // font-weight: 400;
                color: #333333;
              }

              .link-item{
                margin-top: 4px;
                color: #909399;
                

                .item-child{
                  margin-left: 24px;
                }
              }
              &>div:first-child{
                margin-top: 0;
              }

            }

          }
          .mt0{
            margin-top: 0;
          }


        }
      }
    }
  }

}
.slotFooterMain-V2110{
  display: flex;
  margin-top: 12px;
  margin-left: 8px;
  .footerItem{
    font-size: 12px;
    color: #3377FF;
    padding: 4px 16px;
    border-radius: 20px;
    border: 1px solid #3377FF;
    cursor: pointer;
    box-sizing: content-box;
  }
  &>div:last-child{
    margin-left: 12px;
  }
}
// .text{
//   max-width: 608px;
// }
