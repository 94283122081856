.Contacts {
  width: 452px;
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  .contacts-item{
    padding: 12px 0;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #303133;
    border-bottom: 1px solid #E9EBEF;
  
  }
  .item-main{
    &>div:last-child {
      border: none !important;
    }
  }
  // 分页样式
  .pageination-main{
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    li{
      font-size: 12px  !important;
      height: 22px !important;
      line-height: 20px !important;
      min-width: 22px !important;
      margin-left: 8px !important;
      .nofollow{
        padding: 0 7px;
      }
    }
    &li:first-child{
      margin-left: 0 !important;
    }
  }
}

