.receivableBillMobile {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;



  // 修改tab
  .tabs-main{
    // margin-top: calc(16rem/75);
    .Tabs-nav{
      margin-bottom: 0;
      border-bottom: calc(1rem/75) solid #E9EBEF;
      .Tabs-navPointer{
        border-radius: 0;
        height: calc(4rem/75);
        box-shadow: none;
        height: calc(4rem/75);
        border-radius: calc(2rem/75);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: transparent;
        &::after{
          content: '';
          width: calc(144rem/75) !important;
          margin: 0 auto;
          background: #3377FF;
          height: calc(4rem/75);
        }
      }
      
      .Tabs-navItem{
        max-width: calc(144rem/75) !important;
        margin-right: calc(49rem/75);
        .Tabs-navLink{
          background: transparent;
          padding: calc(8rem/75) 0;
          font-size: calc(26rem/75)  !important;
          color: #606266 !important;
          border-radius: 0  !important;
        }
        .Tabs-navLink.active{
          font-weight: 500;
          color: #3377FF !important;
        }
      }
    }

  }
  .tabsMain-navPointer1{
    .Tabs-navPointer{
      transform: translateX(0px) !important;
      width: calc(144rem/75) !important;
    }
  }
  .tabsMain-navPointer2{
    .Tabs-navPointer{
      transform: translateX(calc(193rem/75)) !important;
      width: calc(144rem/75) !important;
    }
  }
  // 列表样式
  .billList{
    // box-sizing: border-box;
    // 0 calc(24rem/75) 
    // display: none;
    .content-item{
      box-sizing: content-box;
      margin-top: calc(20rem/75);
      border-radius: calc(16rem/75);
      border: calc(2rem/75) solid #E9EBEF;

      .ml5{
        margin: 0 calc(5rem/75);
      }


      .item-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: calc(106rem/75);
        padding: calc(16rem/75) calc(24rem/75);
        background: #EAF2FE;
        border-radius: calc(16rem/75) calc(16rem/75) 0px 0px;
        font-size: calc(26rem/75);
        box-sizing: content-box;
        // padding: 0 calc(24rem/75) ;
        .customer-name{
          font-weight: 500;
          color: #333333;
        }
        .number-date{
          font-size:  calc(24rem/75);
          color: #909399;
          word-break: break-all;
        }
      }
      .item-bill{
        margin-top: calc(24rem/75);
        box-sizing: content-box;
        padding: 0 calc(24rem/75) ;
        position: relative;
        padding-bottom: calc(16rem/75);
        .bottom-line{
          // margin: 0 calc(24rem/75);
          border-bottom: calc(2rem/75) solid #E9EBEF;
          margin-top: calc(16rem/75);
        }
        .link-drown_txt{
          position: absolute;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          bottom: calc(20rem/75);
          right: calc(24rem/75);
          
          img{
            width: calc(24rem/75);
            height: calc(24rem/75);
            margin-left: calc(12rem/75);
            transition: all .3s;
          }
          .img-up{
            transform: rotateX(-180deg);
            bottom: calc(-7rem/75);
          }
        }
        .contract-main{
          // margin: 0 calc(24rem/75);
          // border-top: calc(2rem/75) solid #E9EBEF;
          // margin-top: calc(-16rem/75);
          &>div:first-child {
            margin-top: calc(24rem/75) !important;
          }
          .contract-item {
            margin-top: calc(32rem/75);
            font-size: calc(24rem/75);
            .item-line{
              display: flex;
              align-items: center;
            }
            .contract-label{
              width: calc(152rem/75);
              color: #909399;
            }
            .contract-txt{
              flex: 1;
              font-weight: 500;
              color: #333333;
              word-break: break-all;
            }
            .contract-child{
              font-size: calc(24rem/75);
              font-weight: 400;
              color: #909399;
            }
            .mt12{
              margin-top: calc(12rem/75);
            }
          }
        }

        .flex{
          display: flex;
          justify-content: space-between;
          div{
            flex: 1;
          }
        }
        .mt4{
          margin-top: calc(4rem/75);
        }
        .bill-title{
          font-size: calc(26rem/75);
          font-weight: 500;
          color: #333333;
          .img-txt{
            display: flex;
            align-items: center;
            font-size: calc(24rem/75);
            img{
              width: calc(32rem/75);
              height: calc(32rem/75);
              margin-right: calc(8rem/75);
            }
          }
        }


        .txt-des{
          color: #909399;
          font-size: calc(24rem/75);
          font-weight: 400;
        }
        .txtAlignR{
          text-align: right;
        }
      }
      .item-bill_pt56{
        padding-bottom: calc(56rem/75);
      }

    }

    .show-tips{
      margin-top: calc(20rem/75);
      
      display: flex;
      align-items: center;
      img{
        width: calc(24rem/75);
        height: calc(24rem/75);
        margin-right: calc(16rem/75);
      }
      font-size: calc(24rem/75);
      font-weight: 400;
      color: #333333;
    }
    .ml24{
      margin: calc(20rem/75) calc(24rem/75) 0;
    }

    .noBill-item{
      margin-top: calc(20rem/75);
      .link-contract{
        min-width: calc(184rem/75);
        font-size: calc(24rem/75);
        color: #909399;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img{
          margin-left: calc(12rem/75);
          width: calc(24rem/75);
          height: calc(24rem/75);
        }
      }
      .link-bottom{
        position: absolute;
        bottom: calc(24rem/75);
        right: calc(24rem/75);
        .img-up{
          transform: rotateX(-180deg);
          bottom: calc(-7rem/75);
        }
      }
      .heder-bgEAF2FE{
        background: #EAF2FE;
        border-bottom: none !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      
      .item-header{
        display: flex;
        justify-content: space-between;
        // height: calc(70rem/75);
        // padding: calc(23rem/75) 0;
        align-items: center;
        border-radius: calc(16rem/75);
        border: calc(2rem/75) solid #E9EBEF;
        box-sizing: content-box;
        padding: calc(16rem/75) calc(24rem/75);
        .header-name{
          font-size: calc(26rem/75);
          font-weight: 500;
          color: #333333;
          margin-right: calc(10rem/75);
        }

      }

      .item-content{
        border: calc(2rem/75) solid #E9EBEF;
        border-radius: 0 0 calc(16rem/75) calc(16rem/75);
        border-top: none;
        position: relative;
        padding-bottom: calc(72rem/75);

        .list-item{
          margin: 0 calc(24rem/75);
          box-sizing: content-box;
          padding: calc(16rem/75) 0;
          border-bottom: calc(2rem/75) solid #E9EBEF;
          word-break: break-all;
          .index-name{
            .flex1 {
              flex: 1;
            }
          }
          .index-number{
            width: calc(40rem/75);
            .index-txt{
              width: calc(28rem/75);
              height:  calc(28rem/75);
              background: #E0EDFF;
              border-radius: 50%;
              text-align: center;
              line-height: calc(28rem/75);
              font-size: calc(20rem/75);
              font-weight: 500;
              color: #3377FF;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .ml40{
            margin-left: calc(40rem/75);
          }
          .mt18{
            margin-top: calc(18rem/75);
          }
          .mt6{
            margin-top: calc(6rem/75);
          }
          .money-peopleL{
            width: calc(252rem/75);
          }
          .money-peopleR{
            flex: 1;
          }
          .txt-color333333{
            font-size: calc(24rem/75);
            font-weight: 500;
            color: #333333;
          }
          .txt-color909399{
            font-size: calc(24rem/75);
            font-weight: 500;
            color: #909399;
          }
        }
        .flex{
          display: flex;
          align-items: center;
        }
        &>div:last-child{
          border: none !important;
        }
      }

    }


  }

  .v1124_filterBottomMobile{
    .left{
      bottom: inherit !important;
    }
    .ismore {
      .more{    
        left: 50%  !important;
        transform: translateX(-50%) !important;
      }
    }

  }

}
.slotFooterMainMobile-V2110{
  display: flex;
  margin-top: calc(24rem/75);
  .footerItem{
    font-size: calc(24rem/75);
    color: #3377FF;
    padding: calc(6rem/75) calc(36rem/75);
    border-radius: calc(40rem/75);
    border: calc(2rem/75) solid #3377FF;
    font-weight: 400;
    cursor: pointer;
  }
  &>div:last-child{
    margin-left: calc(16rem/75);
  }
}

