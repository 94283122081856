.mobileV2110BillReceivab {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  // 列表样式
  .billList{
    // box-sizing: border-box;
    // 0 calc(24rem/75) 
    // display: none;
    .content-item{
      box-sizing: content-box;
      margin-top: calc(20rem/75);
      border-radius: calc(16rem/75);
      border: calc(2rem/75) solid #E9EBEF;

      .ml5{
        margin: 0 calc(5rem/75);
      }


      .item-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: calc(16rem/75) calc(24rem/75);
        background: #EAF2FE;
        border-radius: calc(16rem/75) calc(16rem/75) 0px 0px;
        font-size: calc(26rem/75);
        box-sizing: content-box;
        word-break: break-all;
        .customer-name{
          font-weight: 500;
          color: #333333;
        }
        .number-date{
          font-size:  calc(24rem/75);
          color: #909399;
          word-break: break-all;
        }
      }
      .item-bill{
        margin-top: calc(24rem/75);
        box-sizing: content-box;
        padding: 0 calc(24rem/75) ;
        position: relative;
        padding-bottom: calc(16rem/75);
        .bottom-line{
          // margin: 0 calc(24rem/75);
          border-bottom: calc(2rem/75) solid #E9EBEF;
          margin-top: calc(16rem/75);
        }
        .link-drown_txt{
          position: absolute;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          bottom: calc(20rem/75);
          right: calc(24rem/75);
          
          img{
            width: calc(24rem/75);
            height: calc(24rem/75);
            margin-left: calc(12rem/75);
            transition: all .3s;
          }
          .img-up{
            transform: rotateX(-180deg);
            bottom: calc(-7rem/75);
          }
        }
        .contract-main{
          // margin: 0 calc(24rem/75);
          // border-top: calc(2rem/75) solid #E9EBEF;
          // margin-top: calc(-16rem/75);
          &>div:first-child {
            margin-top: calc(24rem/75) !important;
          }
          .contract-item {
            margin-top: calc(32rem/75);
            font-size: calc(24rem/75);
            .item-line{
              display: flex;
              align-items: center;
            }
            .contract-label{
              width: calc(152rem/75);
              color: #909399;
            }
            .contract-txt{
              flex: 1;
              font-weight: 500;
              color: #333333;
              word-break: break-all;
            }
            .contract-child{
              font-size: calc(24rem/75);
              font-weight: 400;
              color: #909399;
            }
            .mt12{
              margin-top: calc(12rem/75);
            }
          }
        }

        .flex{
          display: flex;
          justify-content: space-between;
          div{
            flex: 1;
          }
        }
        .mt4{
          margin-top: calc(4rem/75);
        }
        .bill-title{
          font-size: calc(26rem/75);
          font-weight: 500;
          color: #333333;
          .img-txt{
            display: flex;
            align-items: center;
            font-size: calc(24rem/75);
            img{
              width: calc(32rem/75);
              height: calc(32rem/75);
              margin-right: calc(8rem/75);
            }
          }
        }


        .txt-des{
          color: #909399;
          font-size: calc(24rem/75);
          font-weight: 400;
        }
        .txtAlignR{
          text-align: right;
          word-break: break-all;
        }
      }
      .item-bill_pt56{
        padding-bottom: calc(56rem/75);
      }

    }

    .show-tips{
      margin-top: calc(20rem/75);
      
      display: flex;
      align-items: center;
      img{
        width: calc(24rem/75);
        height: calc(24rem/75);
        margin-right: calc(16rem/75);
      }
      font-size: calc(24rem/75);
      font-weight: 400;
      color: #333333;
    }
    .ml24{
      margin: calc(20rem/75) calc(24rem/75) 0;
    }

  }

  .v1124_filterBottomMobile{
    .left{
      bottom: inherit !important;
    }
    .ismore {
      .more{    
        left: 50%  !important;
        transform: translateX(-50%) !important;
      }
    }

  }

}
.slotFooterMainMobile-V2110{
  display: flex;
  margin-top: calc(24rem/75);
  .footerItem{
    font-size: calc(24rem/75);
    color: #3377FF;
    padding: calc(6rem/75) calc(36rem/75);
    border-radius: calc(40rem/75);
    border: calc(2rem/75) solid #3377FF;
    font-weight: 400;
    cursor: pointer;
  }
  &>div:last-child{
    margin-left: calc(16rem/75);
  }
}
