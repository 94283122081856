.v2300MobileProjectBudgetIndex {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  .budgetTipsBox{
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: calc(24rem/75);
    box-sizing: content-box;
    margin-top: calc(23rem/75);
    
    img{
      width: calc(28rem/75);
      height: calc(28rem/75);
      cursor: pointer;
      z-index: 5;
    }
    &:hover{
      .tooltip-main{
        display: block;
      }
    }
    .tooltip-main{
      position: absolute;
      width: calc(500rem/75) !important;
      z-index: 99999;
      right: calc(-22rem/75);
      bottom: calc(43rem/75);
      display: none;
      .tooltip-content{
        // background: rgba(101, 117, 137, 0.8);
        background: rgba(0,0,0, 0.7);
        border-radius: calc(4rem/75);
        padding: calc(12rem/75) calc(16rem/75);
        color: #fff;
        text-align: left;
        position: relative;
        font-size: calc(20rem/75);
      }
      .icon-down{
        position: absolute;
        right: calc(45rem/75);
        font-size: calc(28rem/75);
        // color: #657589;
        // color: 	rgba(101,117,137,0.8);
        color: rgba(0,0,0, 0.7);
        bottom: calc(-24.8rem/75);
      }
      
    }
  }

  // 列表样式
  .billList{
    .saveTxtMain{
      padding-left: calc(60rem/75);
      margin-left: calc(8rem/75);
      color: #C9CED1;
      font-size: calc(20rem/75);
      min-width: calc(160rem/75);
      height: calc(64rem/75);
      line-height: calc(32rem/75);
      text-align: left;
      // padding-right: calc(20rem/75);
      // display: flex;
      // justify-content: flex-end;
      // flex-direction: column;
      // display: none;
      .mL5{
        margin-left: calc(5rem/75);
      }
    }
    .V2300MobileTooltipProjectBudgetIndexTitle{
      .adm-popover-arrow{
        left: calc(30rem/75) !important;
      }
      .adm-popover-inner{
        min-width: calc(100rem/75) !important;
        width: unset !important;
      }
    }
    .saveTxtMainNone{
      // margin-left: calc(8rem/75);
      // color: #C9CED1;
      // font-size: calc(20rem/75);
      // min-width: calc(160rem/75);
      // height: calc(64rem/75);
      // line-height: calc(32rem/75);
      // text-align: left;
      // display: none;
      // .mL5{
      //   margin-left: calc(5rem/75);
      // }
    //   animation: saveTxtAnanimation 2s;
    //   @keyframes identifier {
    //     0% {
    //         transform: scale(1);
    //     }
    //     50% {
    //       transform: scale(1.2);
    //     }
    //     100% {
    //       transform: scale(0);
    //     }
    // }
      padding-left: calc(60rem/75);
      transition: all 2s;
      opacity: 0;
      // transform: scale(0);
    }
    .content-item{
      box-sizing: content-box;
      margin-top: calc(20rem/75);
      border-radius: calc(16rem/75);
      padding: 0 calc(24rem/75);
      // border: calc(2rem/75) solid #E9EBEF;
      .item-header{
        font-weight: 700;
        color: #333333;
        font-size: calc(26rem/75);
        .header-title{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: calc(480rem/75);
        }


        // background: red;
      }
      .setBudgetBox{
        margin-top: calc(12rem/75);
        display: flex;
        align-items: center;
        border-bottom: calc(2rem/75) solid #E9EBEF;
        .budgetInputV2300Mobile{
          width: calc(350rem/75);
          .adm-input-element{
            font-size: calc(24rem/75);
            color: #333333;
            border: none;
            height: calc(64rem/75);
            line-height: calc(64rem/75);
          }
        }
      }

    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  // .budgetTipsBox{
  //   display: flex;
  //   justify-content: flex-end;
  //   padding-right: calc(24rem/75);
  //   box-sizing: content-box;
  //   .v2300MObileContractTipBox{
  //     max-width: calc(540rem/75);
  //     .ant-tooltip-inner{
  //       font-size: calc(24rem/75);
  //     }
  //   }
  //   .contractTipImg{
  //     width: calc(28rem/75);
  //     height: calc(28rem/75);
  //     cursor: pointer;
  //     z-index: 5;
  //   }
  // }

}

