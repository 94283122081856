.mobile-time-short {
    width: calc(320rem/75);
    height: calc(224rem/75);
    background: rgba(62, 70, 77, 0.8);
    box-shadow: 0px calc(2rem/75) calc(20rem/75) 0px rgba(0, 0, 0, 0.1);
    border-radius: calc(16rem/75);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    .warn {
        width: calc(80rem/75);
        height: calc(80rem/75);
        background: url('../images//warn.png') no-repeat;
        background-size: 100% 100%;
        margin-top: calc(40rem/75);
    }
    .time-short {
        font-size: calc(28rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: calc(40rem/75);
        margin-top: calc(24rem/75);
    }
}