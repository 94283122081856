.payment {
        width: 100%;
        background: #FFFFFF;
        main {
            width: 580px;
            background: #F3F6FA;
            margin-top: 8px;
            padding: 12px 20px;
            box-sizing: border-box;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            line-height: 18px;
        }
        #main1 {
            padding-bottom: 0;
            margin-top: 0;
            &>div:nth-child(2) {
                padding-bottom: 12px;
            }
        }
        #main2 {
            margin: 0;
            // margin-bottom: 5px;
        }
        .line2 {
            display: none;
        }
}