.v2110-billPost{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 400px;
  .postList{
    .item-main{
      width: 400px;
      margin: 12px 0;
      border-radius: 6px;
      border: 1px solid #E9EBEF;
      .item-hd{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #EAF2FE;
        box-sizing: content-box;
        padding: 9px 24px;
        font-size: 12px;
        color: #909399;
        .hd-title{
          word-break: break-all;
          font-size: 12px;
          font-weight: 700;
          color: #303133;
          flex: 1;
          margin-right: 5px;
        }
      }
      .item-ct{
        display: flex;
        padding: 12px 24px;
        box-sizing: content-box;
        border-bottom: 1px solid #E9EBEF;
        .ct-main{
          font-size: 12px;
          color: #909399;
          .mt4{
            margin-top: 4px;
          }
          .link-num{
            color: #303133;
            line-height: 18px;
          }
        }

        .ct-index{
          width: 24px;
          .index-num{
            width: 16px;
            // padding: 0 4px;
            height: 16px;
            background: #E0EDFF;
            border-radius: 50%;
            font-size: 12px;
            font-weight: 500;
            color: #3377FF;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &>div:last-child{
        border-bottom: none;
      }
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  
  }
}