.Recommend {
    background: #FFFFFF;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    padding: 12px 12px 12px 20px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    background: url('../images/robotDialog.png')no-repeat;
    background-size: 100% 100%;
    .button {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3377FF;
        line-height: 18px;
        margin-top: 8px;
    }
    .button0 {
        margin: 0;
    }
    .buttons {
        margin-top: 8px;
    }
}