.footer-input {
    width: 100%;
    background-color: #fff;
    font-family: PingFangSC-Regular, PingFang SC;
    position: relative;
    padding: 8px 16px;
    .voice-content{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-ct{
        flex: 1;
        height: 52px;
        .textarea{
          max-width: 100%;
          min-width: calc(100% - 110px);
          margin: 0 auto;
          resize:none;
          outline:none;
          border: none;
          color: #333333;
          // padding: 0 24px;
          box-sizing: border-box;
          font-size: 14px;
          position: absolute;
          z-index: 999;
          height: 44px;
          line-height: 22px;
          &::-webkit-scrollbar{
            display: none;
          }
          &::-webkit-input-placeholder{
              color: #C0C4CC;
              font-size: 14px;
          } /* 使用webkit内核的浏览器 */
          &:-moz-placeholder{
              color: #C0C4CC;
              font-size: 14px;
          } /* Firefox版本4-18 */
          &::-moz-placeholder{
              color: #C0C4CC;
              font-size: 14px;
          } /* Firefox版本19+ */
          &:-ms-input-placeholder{
              color: #C0C4CC;
              font-size: 14px;
          } /* IE浏览器 */
        }
      }
      .voice-send{
        display: flex;
        align-items: center;
        height: 52px;
        .split-line{
          width: 1px;
          height: 32px;
          margin-right: 8px;
          background: #E9EBEF;
        }
        .voiceImg{
          margin: 0 2px;
          min-width: 32px;
          height: 32px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 20px;
            height: 20px;
          }
          .lignt-img{
            display: none;
          }
          &:hover{
            img{
              display: none;
            }
            .lignt-img{
              display: block;
            }
          }
        }
        .send-message{
          min-width: 32px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .maxLength{
            color: #FF475A;
            font-size: 20px;
            transform: scale(.5);
            position: absolute;
            left: -18px;
            bottom: -50%;
            .a {
              color: #C0C4CC;
            }
          }
          .sendImg{
            margin-left: 5px;
            // cursor: pointer;
            img{
              width: 32px;
              height: 32px;
            }
            // .lignt-img{
            //   display: none;
            // }
            // &:hover{
            //   img{
            //     display: none;
            //   }
            //   .lignt-img{
            //     display: block;
            //   }
            // }
          }
          .sendImg2{
            margin-left: 5px;
            cursor: pointer;
            img{
              width: 32px;
              height: 32px;
            }
          }
        }

      }
    }

}