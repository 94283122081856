.v2200-ArPrePaymentIndex{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 580px;
  .mr3{
    margin-right: 3px;
  }
  .pre-paymentList{
    margin: 12px 0;
    padding: 12px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    font-size: 12px;
    .list-title{
      font-weight: 700;
      color: #303133;
    }
    .list-money{
      display: flex;
      color: #333333;
      margin-top: 6px;
      .pre-money{
        width: 216px;
      }
      .flex1{
        flex: 1;
      }
    }
  }
  .pre-paymentMain{
    &>div:last-child{
      margin-bottom: 0;
    }
  }

}