.MobileFirstSentence {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: calc(150rem/75);
    height: calc(50rem/75);
    .img {
        width: calc(24rem/75); 
        height: calc(36rem/75); 
        margin-right: 4px;
        display: flex;
        align-items: center;
    }
    .font {
        font-size: calc(24rem/75); 
        line-height: calc(36rem/75); 
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
    }
    .select-color {
        color: #3377FF;
    }
}