.mobile-nav {
    width: 100%;
    height: 45px;
    background: linear-gradient(270deg, #9ABCFF 0%, #3377FF 100%);
    font-size: calc(32rem/75);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: calc(45rem/75);
    display: flex;
    align-items: center;
    justify-content: center;
    .tip {
        width: calc(32rem/75);
        height: calc(32rem/75);
        margin-left: calc(16rem/75);
    }
    .mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999999999999999999;
    }
    .mobile-detail {
        width: 10rem;
        // height: calc(642rem/75);
        background: #F5F8FC;
        box-sizing: border-box;
        position: absolute;
        overflow-y: scroll;
        // box-sizing: content-box;
        height: calc(642rem/75);
        padding: calc(40rem/75) 0 calc(44rem/75);
    }
    .div-1 {
        width: calc(160rem/75);
        height: calc(36rem/75);
        background: #E0EDFF;
        border-radius: calc(36rem/75);
        display: flex;
        // margin-top: calc(40rem/75);
        box-sizing: border-box;
        align-items: center;
        margin-left: calc(32rem/75);
    }
    .div-1-1 {
        width: calc(36rem/75);
        height: calc(36rem/75);
        background: #3377FF;
        text-align: center;
        line-height: calc(36rem/75);
        border-radius: 50%;
        font-size: calc(24rem/75);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .div-1-2 {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        height: calc(36rem/75);
        margin-left: calc(8rem/75);
        // line-height: calc(36rem/75);
        display: flex;
        align-items: center;
    }
    .main-1 {
        // width: calc(540rem/75);
        width: calc(686rem/75);
        font-size: calc(26rem/75);
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #333333;
        line-height: calc(36rem/75);
        // margin-left: calc(32rem/75);
        // margin-top: calc(17rem/75);
        margin: calc(17rem/75) calc(32rem/75) 0 calc(32rem/75);
    }
    .div-2 {
        width: calc(208rem/75);
        height: calc(36rem/75);
        background: #E0EDFF;
        border-radius: calc(36rem/75);
        display: flex;
        margin-top: calc(41rem/75);
        margin-left: calc(32rem/75);
        box-sizing: border-box;

    }
    .div-1-1 {
        width: calc(36rem/75);
        height: calc(36rem/75);
        background: #3377FF;
        text-align: center;
        line-height: calc(36rem/75);
        border-radius: 50%;
        font-size: calc(24rem/75);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .main-2 {
        // width: calc(640rem/75);
        width: calc(686rem/75);
        font-size: calc(26rem/75);
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #333333;
        line-height: calc(36rem/75);
        // margin-top: calc(17rem/75);
        margin: calc(17rem/75) calc(32rem/75) 0 calc(32rem/75);
        // margin-left: calc(32rem/75);
    }
    .div-3 {
        width: calc(160rem/75);
        height: calc(36rem/75);
        background: #E0EDFF;
        border-radius: calc(18rem/75);
        margin-top: calc(41rem/75);
        margin-left: calc(32rem/75);
        display: flex;
        box-sizing: border-box;
    }
    .main-3 {
        // width: calc(540rem/75);
        width: calc(686rem/75);
        font-size: calc(26rem/75);
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #333333;
        line-height: calc(36rem/75);
        margin: calc(16rem/75) calc(32rem/75) calc(0rem/75) calc(32rem/75);
    }
}