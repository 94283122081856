.mobileV2200ArPrePaymentIndex {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  margin-top: calc(4rem/75);
  .pre-paymentList{
    margin: calc(20rem/75) 0;
    border-radius: calc(16rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    padding: calc(24rem/75);
    text-align: left;
    box-sizing: content-box;
    word-break: break-all;
    .list-title{
      font-size: calc(26rem/75);
      font-weight: 700;
      color: #333333;
    }
    .list-money{
      font-size: calc(24rem/75);
      .pre-money{
        color: #333333;
        margin-top: calc(16rem/75);
        .pre-money_label{
          color: #909399;
        }
      }
      .mt8{
        margin-top: calc(8rem/75);
      }
    }
  }

  .list-main{
    &>div:last-child{
      margin-bottom: 0;
    }
  }
}

