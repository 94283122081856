.mobileMoneyDetail {
    position: relative;
    .money {
        width: calc(540rem/75);
        border-bottom: 1px solid #E9EBEF;
        font-size: calc(24rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: calc(33rem/75);
        display: flex;
        align-items: center;  
        box-sizing: border-box;
        padding: calc(16rem/75) 0;       
        .date {
            font-size: calc(24rem/75);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: calc(33rem/75);
            margin-right: calc(24rem/75);
        }
    }
    .money:nth-last-child(2) {
        border-bottom: none;
    }
    .lastmoney:nth-last-child(2) {
        border-bottom: none;
        margin-bottom: calc(52rem/75);
    }
    .flex {
        display: flex;
    }
    .align-item {
        align-items: center;
    }
    .width {
        width: calc(96rem/75);
        height: calc(33rem/75);
        margin-right: calc(8rem/75);
    }
    img {
        vertical-align: top;
    }
    .filter-bottom {
        position: absolute;
        bottom: calc(-55rem/75);
        height: calc(90rem/75);
        width: 100%;
        .flex {
            display: flex;
        }
        img {
            vertical-align: top;
        }
        .align-item {
            align-items: center;
        }
        .filter {
            width: calc(540rem/75);
            height: calc(32rem/75);
            background: #FFFFFF;
            filter: blur(calc(10rem/75));
        }
        .left {
            margin-right: calc(16rem/75);
            position: absolute;
            bottom: calc(18rem/75);
        }
        .pack-up {
            font-size: calc(24rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #909399;
            margin-right: calc(8rem/75);
        }
        .ismore {
            height: calc(48rem/75);
            align-items: center;
            .more {
                width: calc(136rem/75);
                height: calc(48rem/75);
                border-radius: calc(24rem/75);
                border: calc(2rem/75) solid #DCDFE6;
                font-size: calc(24rem/75);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: calc(227rem/75);
            }
        }
    }
}
.filter-bottom {
    .flex {
        display: flex;
    }
    img {
        vertical-align: top;
    }
    .align-item {
        align-items: center;
    }
    .filter {
        width: 100%;
        height: calc(32rem/75);
        background: #FFFFFF;
        filter: blur(calc(10rem/75));
    }
    .left {
        margin-right: calc(16rem/75);
    }
    .pack-up {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        margin-right: calc(8rem/75);
    }
    .ismore {
        height: calc(48rem/75);
        align-items: center;
        .more {
            width: calc(136rem/75);
            height: calc(48rem/75);
            border-radius: calc(24rem/75);
            border: calc(2rem/75) solid #DCDFE6;
            font-size: calc(24rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: calc(227rem/75);
            .loading {
                width: calc(40rem/75);
                animation: identifier 1s linear infinite;
            }
        }
    }
    .pick-style {
        width: calc(120rem/75);
        height: calc(32rem/75);
        justify-content: flex-end;
    }
}