.knowledges {
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
    .bigUrl {
        width: 90%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // & > div {
        //     width: 30px;
        //     height: 30px;
        //     background-color: red;
        //     position: absolute;
        //     right: -15px;
        //     top: -15px;
        //     background: url(../images/close.png) no-repeat;
        //     background-size: 100% 100%;
        // }
    }
    .knowledge {
        background: #FFFFFF;
        color: #333333;
        padding: 12px 10px 12px 10px;
        margin-left: 8px;
        position: relative;
        border-radius: 8px;
        line-height:25px;
        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 5px solid #FFFFFF;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            background-color: transparent;
            transform: rotate(225deg);
            position: absolute;
            left: -5px;
            top: 0;
        }
        #se-knowledge {
            p {
                font-family: PingFangSC-Regular, PingFang SC;
            }
        }
    }
    .mask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;
    }
}
