.cancel-com {
    width: calc(320rem/75);
    height: calc(320rem/75);
    background: rgba(62, 70, 77, 0.8);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: calc(16rem/75);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .cancel-img {
        width: calc(102rem/75);
        height: calc(126rem/75);
        position: absolute;
        top: calc(60rem/75);
        left: calc(109rem/75);
    }
    span {
        font-size: calc(28rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        position: absolute;
        bottom: calc(45rem/75);
        left: calc(34rem/75);
    }
}