.MobileV2300CarouselMain{
  background: transparent;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  width: calc(652rem/75);
  height: calc(484rem/75);
  background: #FFFFFF;
  box-shadow: 0 0  calc(20rem/75)  calc(2rem/75) rgba(0,43,128,0.08);
  border-radius: calc(8rem/75);
  box-sizing: border-box;
  padding: calc(32rem/75);
  font-size: calc(24rem/75);
  position: absolute;
  z-index: 9;
  .queryImg{
    margin: 0 auto;
    width: calc(580rem/75);
    height: calc(56rem/75);
    display: flex;
    justify-content: center;
    img{
      width: calc(137rem/75);
      height: calc(28rem/75);
    }
  }
  .carouse-ct{
    width: calc(580rem/75) !important;
    background: #FFFFFF;
    display: flex !important;
    box-sizing: content-box;
    align-items: center;
    font-size: calc(24rem/75) !important;
    line-height: 1 !important;
    box-sizing: content-box;
    padding: calc(1rem/75);
  }
  .top-line{
    height: calc(2rem/75);
    background: #E9EBEF;
  }
  .query-content{
    border: none;
    .queryH64{
      margin: calc(16rem/75) 0 calc(8rem/75);
      width: calc(580rem/75) !important;
      height: calc(128rem/75);
      display: flex;
      align-items: center;
    }
    .bill-line{
      width: calc(2rem/75);
      height: calc(128rem/75);
      margin: calc(16rem/75) 0;
    }
    
    .bill-title{
      width: calc(104rem/75);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-weight: 700;
      color: #333333;
      font-size: calc(24rem/75);
      height: calc(128rem/75);
      .bill-img{
        width: calc(28rem/75);
        height: calc(28rem/75);
        margin-bottom: calc(8rem/75);
      }
    }

    .bill-content{
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: calc(32rem/75);
      height: calc(128rem/75);

      .recommend-txt{
        // flex: 1;
        width: calc(320rem/75);
        height: calc(128rem/75);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #3377FF;
        line-height: calc(40rem/75);
        div{
          width: calc(320rem/75);
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          text-overflow: ellipsis;
        }
    
      }

      .refresh-main{
        color: #909399;
        cursor: pointer;
        min-width: calc(120rem/75);
        display: flex;
        // justify-content: flex-end;
        // justify-content: center;
        .refresh-img{
          display: none;
        }
        .refresh-Default{
          display: block;
        }
        img{
          width: calc(24rem/75);
          height: calc(24rem/75);
          margin-right: calc(8rem/75);
        }
        &:hover{
          color: #3377FF;
          .refresh-img{
            display: block;
          }
          .refresh-Default{
            display: none;
          }
        }
      }
    }
  }
  .carousel-pannel{
    width: calc(580rem/75);
    font-size: calc(24rem/75);
    .slick-slider{
      height: calc(396rem/75);
    }
    .slick-dots{
      bottom: 0;
      margin-bottom: calc(32rem/75) !important;
      z-index: 9999999;
      height: calc(12rem/75) !important;
      li{
        width: calc(12rem/75) !important;
        height: calc(12rem/75) !important;
        border-radius: 50% !important;
        background: #C0C4CC;
        button{
          display: none !important;
        }
      }
      .slick-active{
        background: #3377FF;
      }
    }
    .ant-carousel{
      height: calc(396rem/75);
      font-size: calc(24rem/75);
      line-height: 1;
    }
  }

}