.mobile-line {
    font-size: calc(24rem/75);
    font-weight: 400;
    color: #FFFFFF;
    line-height: calc(33rem/75);
    .tooltip-mobile {
        box-shadow: 0px calc(2rem/75) calc(20rem/75) 0px rgba(0, 42, 128, 0.1);
        border-radius: calc(10rem/75);
    }
    .a1::before {
        content: '';
        display: block;
        width: calc(20rem/75);
        height: calc(20rem/75);
        background: #3377FF;
        border: calc(3rem/75) solid #FFFFFF;
        border-radius: 50%;
        position: relative;
        top: 5px;
        margin-right: calc(7rem/75);
    }
    .a1 {
        display: flex;
        color: #FFFFFF;
        font-size: calc(24rem/75);
    }
    .a2::before {
        content: '';
        display: block;
        width: calc(20rem/75);
        height: calc(20rem/75);
        background: #FFAA00;
        border: calc(3rem/75) solid #FFFFFF;
        border-radius: 50%;
        position: relative;
        top: 5px;
        margin-right: calc(7rem/75);
    }
    .a2 {
        display: flex;
        color: #FFFFFF;
        font-size: calc(24rem/75);
    }
}