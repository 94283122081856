.v2110MobileApContractAmountorPay {
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  position: relative;

  // 列表样式
  .billList{
    .content-item{
      box-sizing: content-box;
      margin-top: calc(20rem/75);
      border-radius: calc(16rem/75);
      border: calc(2rem/75) solid #E9EBEF;

      .ml5{
        margin: 0 calc(5rem/75);
      }

      .fontW700{
        font-weight: 700;
        color: #333333;
      }

      .item-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: calc(16rem/75) calc(24rem/75);
        background: #EAF2FE;
        border-radius: calc(16rem/75) calc(16rem/75) 0px 0px;
        font-size: calc(26rem/75);
        box-sizing: content-box;

        .number-date{
          font-size:  calc(24rem/75);
          color: #909399;
          word-break: break-all;
          margin-top: calc(2rem/75);
        }
      }
      .item-bill{
        margin-top: calc(24rem/75);
        box-sizing: content-box;
        padding: 0 calc(24rem/75) ;
        position: relative;
        padding-bottom: calc(16rem/75);
        word-break: break-all;
        .bottom-line{
          // margin: 0 calc(24rem/75);
          border-bottom: calc(2rem/75) solid #E9EBEF;
          margin-top: calc(16rem/75);
        }
        .flex{
          display: flex;
          justify-content: space-between;
          // flex-wrap: wrap;
          // div{
          //   flex: 1;
          // }
          .flex1 {
            flex: 1;
          }
        }
        .mt4{
          margin-top: calc(4rem/75);
        }
        .bill-title{
          font-size: calc(24rem/75);
          font-weight: 500;
          color: #333333;
        }


        .txt-des{
          color: #909399;
          font-size: calc(24rem/75);
          font-weight: 400;
        }
        .txtAlignR{
          margin-left: calc(5rem/75);
          text-align: right;
          // flex: 1;
          width: calc(180rem/75);
        }
      }
      .item-bill_pt56{
        padding-bottom: calc(56rem/75);
      }

    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

}

