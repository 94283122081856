.contract-number_main {
  border-radius: calc(10rem/75);
  font-size: calc(24rem/75);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  .list-main{
    font-size: calc(24rem/75);
    // padding: 0 calc(0rem/75) calc(8rem/75) calc(0rem/75);;
    // padding-bottom: calc(0rem/75);
    box-sizing: border-box;
    // margin-bottom: calc(52rem/75);
    &>div:last-child {
      border: none !important;
    }
    .item-main{
      padding: calc(16rem/75) 0;
      box-sizing: border-box;
      border-bottom: 1px solid #E9EBEF;
    }
    .MLR8{
      margin: 0 calc(8rem/75);
    }
  }
  .mobile-filter-bottom {
    position: absolute;
    bottom: calc(-55rem/75);
    height: calc(90rem/75);
    width: 100%;
    .flex {
        display: flex;
    }
    img {
        vertical-align: top;
    }
    .align-item {
        align-items: center;
    }
    .filter {
        height: calc(32rem/75);
        background: #FFFFFF;
        filter: blur(calc(10rem/75));
    }
    .left {
        margin-right: calc(16rem/75);
        position: absolute;
        bottom: calc(18rem/75);
    }
    .pack-up {
        font-size: calc(24rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        margin-right: calc(8rem/75);
    }
    .ismore {
        height: calc(48rem/75);
        align-items: center;
        .more {
            width: calc(136rem/75);
            height: calc(48rem/75);
            border-radius: calc(24rem/75);
            border: calc(2rem/75) solid #DCDFE6;
            font-size: calc(24rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: calc(227rem/75);
            .loading {
              width: calc(40rem/75);
              animation: identifier 1s linear infinite;
            }
        }
    }
    .pick-style {
      width: calc(120rem/75);
      height: calc(32rem/75);
      justify-content: flex-end;
    }
  }
}