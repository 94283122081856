.v2300-ProjectBudgetIndex{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // margin-top: 10px;
  width: 550px;
  // width: 580px;
  .budgetMain{
    margin-top: 12px;

    .projectBudgetIndexMain{
      .ant-tooltip-inner{
        min-width: 50px !important;
      }
    }

    // width: 508px;
    .tr-L{
      width: 222px;
      box-sizing: content-box;
      padding-left: 16px;
    }
    .tr-R{
      width: 222px;
      box-sizing: content-box;
      padding-left: 16px;
      // position: relative;
      // margin-left: 8px;
    }
    .saveTxtMain{
      color: #C9CED1;
      font-size: 12px;
      position: absolute;
      height: 56px;
      right: -43px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .saveTxtMainNone{
      transition: all 2s;
      opacity: 0;
    }
    .mL20{
      margin-left: 20px;
    }
    .mL8{
      margin-left: 8px;
    }
    .filterName-box{
      position: relative;
      .FilterIcon{
        font-size: 10px;
        color: #3377FF;
      }
      .search-main{
        background: #FFFFFF;
        width: 250px;
        position: absolute;
        z-index: 10;
        right: 21px;
        bottom: -10px;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
        // height: 150px;
        .shaixuanTop{
          line-height: 30px;
          padding: 0 8px;
          background: #FFFFFF;
          position: relative;
          .shaixuanTopIcon{
            background: #fff;
            // box-shadow: 0 0 5px rgba(0, 0 ,0 , 30%);
            display: inline-block;
            height: 10px;
            // left: 47%;
            position: absolute;
            // top: -5px;
            transform: rotate(45deg);
            width: 10px;
            right: -5px;
            bottom: -62px;
          }
        }
        .shaixuanCont{
          background: #f3f7fa;
          // max-height: 150px;
          // min-height: 70px;
          overflow: auto;
          padding: 6px 15px;
        }
        .shaixuanBot{
          display: flex;
          justify-content: flex-end;
          padding: 8px 0;
          position: relative;
          overflow: hidden;
          z-index: 5;
          .shaixuan-item{
            border-radius: 3px;
            font-size: 13px;
            margin-right: 10px;
            background: #fff;
            border: 1px solid #dcdfe6;
            color: #5a6879;
            height: 20px;
            line-height: 20px;
            text-align: center;
            padding: 0 18px;
            cursor: pointer;
          }
          .confirm{
            background: #37f;
            border: 1px solid #37f;
            color: #fff;
          }
        }

      }
    }
    .split-line{
      margin-left: 8px;
      width: 1px;
      height: 22px;
      background: #E6EAEC;
    }
    .budgetHd{
      height: 56px;
      // line-height: 56px;
      background: #F2F3F5;
      display: flex;
      align-items: center;
      width: 444px;
    }
    .list-set_money{
      display: flex;
      align-items: center;
      width: 444px;
      border-bottom: 1px solid #E6EAEC;
      height: 56px;
      position: relative;
    }
    .name-search{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .filter-line{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .remindList{
      display: flex;
      height: 56px;
      align-items: center;

      .budgetInputV2300PC{
        width: 190px;;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #D0D6DA;
        font-size: 12px;
        padding: 5px 10px;
      }

      .project-name{
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }

    }
  }

  .budgetMain{
    &>div:last-child{
      margin-bottom: 0;
    }
  }

}