.ChatForm {
    width: 100%;
    background: #FFFFFF;
    word-break: break-all;
    .v2221-list_main{
      margin-top: 8px;
      .list-item{
        width: 540px;
        background: #F3F6FA;
        padding: 0px 20px;
        box-sizing: content-box;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
        line-height: 18px;
        background-color: #F3F6FA;
        .item-content{
          // border-bottom: 1px solid #E9EBEF;
          padding: 12px 0;
          position: relative;
          &::after{
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #E9EBEF;
          }
          .money-txt {
            color: #FFAA00;
          }
      
          .contract-main{
            margin-bottom: 8px;
            .name-txt{
              color: #3377FF;
            }
          }
        }
      }
      &>div:last-child{
        .item-content{
          &::after{
            content: '';
            display: none;
          }
        }
      }
    }
 
  

    .line {
        width: 100%;
        height: 1px;
        background: #E9EBEF;
        margin: 0 auto;
    }
    .margin-top {
        margin-top: 8px;
    }
    .font {
        font-size: 12px;
        color: #3377FF;
        line-height: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
    }
    .title {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    .margin-bottom {
        margin-bottom: 6px;
    }
    .cursor {
        cursor: pointer;
    }
}