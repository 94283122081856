.accounts-list {
    .accounts {
        width: 566px;
        height: 80px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding: 17px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        .icon {
            width: 32px;
            height: 32px;
            margin-right: 8px
        }
        .middle-content {
            flex: 1;
            &>:last-child {
                display: flex;
                justify-content: space-between;
                margin-top: 8px
            }
        }
        span {
            font-size: 12px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #909399;
            line-height: 17px;
        }
    }
    .hover:hover {
        border: 1px solid rgba(51, 119, 255, 1);
    }
    .cursor {
        cursor: pointer;
    }
    .margin-bottom {
        margin-bottom: 12px;
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(192, 196, 204, 1);
        line-height: 18px;
        cursor: pointer;    
        .color {
            color: rgba(51, 119, 255, 1);
        }
        .disabled {
            cursor: not-allowed;;
        }
    }

} 
.acc-margin-top {
    margin-top: 0 !important;
}
