.v2110-taxDetail{
  .billList{
    .item-main{
      width: 580px;
      box-sizing: content-box;
      .content-item{
        background: #FFFFFF;
        margin: 12px 0;
        border-radius: 6px;
        border: 1px solid #E9EBEF;

        .item-title {
          font-size: 12px;
          font-weight: 700;
          color: #303133;
          padding: 9px 24px;
          background: #EAF2FE;
          border-radius: 6px 6px 0px 0px;
        }
        .item-content{
          padding: 12px 24px;
          .txt-des{
            .des-item{
              display: flex;
              font-size: 14px;
              font-weight: 700;
              color: #303133;
              word-break: break-all;
              .icon-txt{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                width: 212px;
                img{
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                }
              }
              .flex1{
                flex: 1;
              }
              .fee-number{
                width: 212px;
              }

              .ml5{
                margin: 0 5px;
              }

              .des-money{
                width: 134px;
              }
            }
            .des-item_title{
              font-weight: 400;
              color: #909399;
              font-size: 12px;
            }
          }

        }
      }
    }
  }
}