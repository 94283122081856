.v2200MobileArBaddebtContractIndex{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;

  .baddebtList{
    margin: calc(4rem/75) 0;
    word-break: break-all;
    .content-item{
      margin: calc(20rem/75) 0;
      padding: calc(24rem/75);
      box-sizing: content-box;
      border-radius: calc(16rem/75);
      opacity: 1;
      border: calc(2rem/75) solid #E9EBEF;
      .list-title{
        font-weight: 700;
        color: #303133;
      }
      .badMoney{
        margin-top: calc(16rem/75);
        color: #909399;
        font-size: calc(24rem/75);
        .bad-title{
          color: #333333;
        }
      }
      .contract-number{
        margin-top: calc(8rem/75);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: calc(24rem/75);
        color: #909399;
        img{
          margin-left: calc(12rem/75);
          width: calc(24rem/75);
          height: calc(24rem/75);
        }
        .img-up{
          transform: rotateX(-180deg);
        }
      }  
      &>div:last-child{
        margin-bottom: 0;
      }
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  .mt8{
    margin-top: calc(8rem/75);
  }

  .selLinkContract{
    margin-top: calc(16rem/75);
    font-size: calc(24rem/75);
    color: #3377FF;
  }

  .color-FF475A{
    color: #FF475A;
  }

  .color-909399{
    color: #909399;
  }
  

  .mlr8{
    margin: 0 calc(8rem/75);
  }

  .chilrldContract-content{
    font-size: calc(24rem/75);
    padding: calc(24rem/75);
    box-sizing: content-box;
    border-radius: calc(12rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    word-break: break-all;
    margin: calc(16rem/75) 0;
    background: #F3F6FA;
    color: #333333;

    .contract-name{
      font-weight: 700;
    }
    .followup-txt{
      margin-top: calc(16rem/75);
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  .chilrldContract-content:last-child{
    margin-bottom: 0 !important;
  }

  .childMain{
    
    .list-box{
      &>div:last-child{
        margin-bottom: 0;
      }
    }
    .list-main{
      margin: calc(24rem/75) 0;
      border-radius: calc(16rem/75);
      border: calc(2rem/75) solid #E9EBEF;
      padding: calc(24rem/75);
      text-align: left;
      box-sizing: content-box;
      color: #333333;
      word-break: break-all;
      .title{
        font-weight: 700;
      }
      .followup_name{
        margin-top: calc(16rem/75);
      }
    }
  }

}