.recommendPCMain{
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  // width: 452px;
  max-width: 588px;
  min-width: 196px;
  .info-list{
    // margin-top: 6px;
    .list-item{
      cursor: pointer;
      margin-bottom: 6px;
      font-size: 12px;
      color: #3377FF;
      line-height: 18px;
    }
    .list-item_circle{
      padding: 0px 20px;
      height: 28px;
      line-height: 28px;
      border-radius: 14px;
      border: 1px solid #3377FF;
      cursor: pointer;
      color: #3377FF;
      margin-right: 8px;
      margin-top: 6px;
      margin-bottom: 0;
      text-align: center;
      box-sizing: content-box;
    }
  }
  .info-list_circle{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

}