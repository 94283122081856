.v2300-ZKGJProjectBudgetContract{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  width: 580px;
  .remainingMoneyTxt{
    color: #FFAA00;
  }
  .budgetList{
    margin: 12px 0;
    padding: 12px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    font-size: 12px;
    word-break: break-all;
    position: relative;
    .list-contract{
      color: #333333;
      margin-top: 6px;
      .contract-name{
        font-weight: 700;
        color: #333333;
      }
      .contract-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .bad-money{
        flex: 1;
        margin-right: 8px;
      }
      .link-contract{
        align-items: center;
        display: flex;
        cursor: pointer;

        img{
          width: 14px;
          height: 14px;
          margin-left: 4px;
          transition: all .3s;
        }
        .img-up{
          transform: rotateX(-180deg);
        }
      }
    }
  }
  .budgetMain{
    &>div:last-child{
      margin-bottom: 0;
    }
  }
  .budgetInputV2300PC{
    width: 120px;
    height: 20px;
    background: #F3F6FA;
    border-radius: 4px;
    border: none;
    border-bottom: 1px solid #D0D6DA;
    font-size: 12px;
    padding: 5px 10px;
    color: #333333;
  }

  .mlr5{
    margin: 0 5px;
  }
  .mt8{
    margin-top: 8px;
  }
  .fontW700{
    font-weight: 700;
  }

  .setBudgetMain{
    color: rgb(51, 119, 255); 
    cursor: pointer;
  }
  .editebudgetTxt{
    cursor: pointer;
    margin-left: 5px;
    color: rgb(51, 119, 255); 
  }

  .contract-ct{
    padding: 8px 20px;
    box-sizing: content-box;
    border-radius: 6px;
    border: 1px solid #E9EBEF;
    word-break: break-all;
    margin: 8px 0;
    background: #F3F6FA;
    color: #333333;
    position: relative;
    .w260{
      width: 260px;
      min-width: 260px;
      margin-right: 5px;
    }
  
    .money-date{
      display: flex;
      align-items: center;
    }

    .v2300ContractTipBox{
      max-width: 380px !important;
      .ant-tooltip-content{
        .ant-tooltip-arrow{
          // right: 10px !important;
          left: 77% !important;
        }
      }

    }

    .title-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .budgetFlex{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    }

  }
  .childList{
    font-size: 12px;



    .selLinkContract{
      color: #3377FF;
      line-height: 18px;
      cursor: pointer;
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

}