.MobileOverdue {
    border-radius: calc(10rem/75);
    font-size: calc(24rem/75);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: calc(24rem/75);
    background-color: #fff;
    word-break: break-all;
    .header {
        font-size: calc(26rem/75);
        line-height: calc(42rem/75);
        margin-bottom: calc(24rem/75);
    }
    .margin-bottom {
        margin-bottom: calc(25rem/75)
    }
    .line {
        width: 100%;
        height: 1px;
        background: #E9EBEF;
    }
    .noline {
        width: 100%;
        height: 1px;
        background: rgba(243, 246, 250, 1);
    }
    .look-more {
        margin: calc(18rem/75);
        color: #3377FF;
        margin: 0;
    }
    #line2 {
        background-color: #F3F6FA;
        margin-bottom: 0
    }
}