.MobileKnowledges {
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    .bigUrl {
        width: 100%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }
    .mask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;
    }
    .MobileKnowledge {
        #se-knowledge {
            p {
                font-family: PingFangSC-Regular, PingFang SC;
            }
        }
        background: #FFFFFF;
        color: #333333;
        padding: 10px 8px 10px 8px;
        margin-left: 6px;
        border-radius: calc(15rem/75);
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 5px solid #FFFFFF;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            background-color: transparent;
            transform: rotate(225deg);
            position: absolute;
            left: -5px;
            top: 0;
        }
    }

}
.wx-image-viewer .viewer-image-pointer {
    display: none !important;
}
