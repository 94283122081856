:root {
    --brand-3: #3377FF ;
}
@supports (top: constant(safe-area-inset-top)) {
    :root {
        --safe-top: 0;
        --safe-bottom: 0;
    }
}
@supports (top: env(safe-area-inset-top)) {
    :root {
        --safe-top: 0 ;
        --safe-bottom: 0 ;
    }
}
.Bubble{
    font-size: 14px;
    font-weight: 400;
}
body {
    font-size: 14px !important;
}
.PullToRefresh-inner {
    background-color: #F4F8FE ;
}
.QuickReplies {
    background-color: #F4F8FE ;
}
.PullToRefresh:hover {
    &::-webkit-scrollbar-thumb{
        border-radius: 7px;
        background-color: #C0C4CC;
    }
}
.Toast-message {
    font-size: 14px;
    color: #333;
}
.Typing-dot {
    background: #C0C4CC;
}
.Avatar--md {
    border-radius: 50%;
    overflow: hidden;
}
::-webkit-scrollbar {
    background: #F4F8FE;
    border-radius: 3px;
    width: 6px;
    padding-bottom: 50px;
}
::-webkit-scrollbar-thumb{
    border-radius: 7px;
    background-color: #F4F8FE;
}
button[aria-label = '下一页']:hover {
    color: #3377FF !important;
}
button[aria-label = '上一页']:hover {
    color: #3377FF !important;
}
.QuickReply-inner {
    color: #3377FF;
    font-weight: 400;
}
.SystemMessage {
    color: #C0C4CC;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}
time[class='Time'] {
    font-size: 12px !important;
    color: #C0C4CC;
    font-weight: 300;
    line-height: 18px;
    font-family: PingFangSC-Light, PingFang SC;
}
.QuickReply:hover {
    background: #EAF2FE;
    opacity: .8;
    border: 1px solid #EAF2FE !important;
}
.QuickReply {
    outline: 0 !important;
    font-family: PingFangSC-Regular, PingFang SC;
  }
.IconBtn  {
    border: none !important;
}
p {
    margin: 0;
}


.Message+.Message {
    margin-top: 20px;
}
.MessageList .Message:nth-child(n+2):nth-child(-n+3){
  .Message-meta{
    display: none;
  }
}


.ChatFooter  {
    padding-bottom: 0;
    // .ScrollView{
    //   padding: 2px 0;
    // }
    .ScrollView .IconBtn{
      font-size: 0 !important;   
      display: none !important;  
    }
    // .ScrollView-inner{
    //   padding-left: 16px !important;  
    // }
    .ScrollView-scroller{
      margin-bottom: -13px !important;  
    }
    .ScrollView-item{
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      padding-right: 0 !important;
    }
  
}
.ScrollView-control {
    outline: 0 !important;
}
button {
    outline: 0 !important;
}
.ChatApp {
   font-family: PingFangSC-Light, PingFang SC;
   line-height: 1;
}
img {
    width: 100%;
}