.m-input {
    // @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
    //     & {
    //       margin-bottom: constant(safe-area-inset-bottom); /* 当然也可以写 margin、bottom …… */
    //       margin-bottom: env(safe-area-inset-bottom);
    //     }
    // }
    width: 100%;
    padding-top: calc(15rem/75);
    padding-bottom: calc(20rem/75);
    background-color: #F4F8FE ;
    position: relative;
    box-sizing: border-box;
    margin-top: calc(-12rem/75);
    .left {
        float: left;
        width: calc(72rem/75);
        height: calc(72rem/75);
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: calc(20rem/75);
        bottom: calc(25rem/75);
        div {
            width: calc(40rem/75);
            height: calc(40rem/75);
        }
    }
    .x-input {
        width: calc(540rem/75);
        margin-left: calc(12rem/75);
        border-radius: calc(48rem/75);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        font-size: calc(24rem/75);
        font-weight: 400;
        color: #333333;
        padding: calc(5rem/75) calc(5rem/75);
        box-sizing: border-box;
        margin: 0 calc(104rem/75);
        box-sizing: border-box;
        z-index: 999;
        .Input--outline {
            border: none;
            outline:none;
            width: 95%;
            font-size: calc(28rem/75);
            min-height: calc(72rem/75);
            line-height: calc(52rem/75);
            font-family: PingFangSC-Regular, PingFang SC !important;
            &::placeholder {
                font-size: calc(28rem/75);
                font-weight: 400;
                color: #C0C4CC;
                line-height: calc(52rem/75);
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .say {
            -webkit-touch-callout:none;  /*系统默认菜单被禁用*/   
            -webkit-user-select:none; /*webkit浏览器*/   
            -khtml-user-select:none; /*早期浏览器*/   
            -moz-user-select:none;/*火狐*/   
            -ms-user-select:none; /*IE10*/   
            user-select:none;
            font-size: calc(28rem/75);
            font-weight: 400;
            color: #333333;
            line-height: calc(72rem/75);
            height: calc(72rem/75);
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-family: PingFangSC-Regular, PingFang SC;;
        }
    }
    .select-say {
        background-color: #E9EBEF ;
    }
    .right {
        user-select:none;
        float: right;
        width: calc(72rem/75);
        height: calc(72rem/75);
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: calc(20rem/75);
        bottom: calc(25rem/75);
        div {
            width: calc(40rem/75);
            height: calc(40rem/75);
        }
    }
    .maxLength {
        font-size: calc(20rem/75);
        font-weight: 400;
        color: #C0C4CC ;
        line-height: calc(28rem/75);
        position: absolute;
        right: calc(13rem/75);
        top: 50%;
        transform: translateY(-50%);
        font-family: PingFangSC-Regular, PingFang SC !important;
    }
}