.card {
    width: calc(540rem/75);
    height: calc(380rem/75);
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 42, 128, 0.08);
    margin: 0 auto;
    border-radius: calc(8rem/75);
    position: relative;
    display: flex;
    box-sizing: border-box;
    .left {
        width: calc(140rem/75);
        height: 100%;
        strong {
            color: #333333;
            font-size: calc(28rem/75);
            position: absolute;
            top: calc(24rem/75);
            left: calc(18rem/75);
            line-height: calc(40rem/75);
            font-weight: 600;
        }
        .signser {
            width: calc(132rem/75);
            position: absolute;
            top: calc(72rem/75);
            left: calc(8rem/75);
        }
    }
    .right {
        flex: 1;
        height: 100%;
        .li-1 {
            margin-top: calc(12rem/75);
        }
        li {
            display: flex;
            width: calc(514rem/75);
            padding: calc(11rem/75) 0;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E9EBEF;
            margin: 0 auto;
            font-size: calc(25rem/75);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3377FF;
            line-height: calc(34rem/75);
            span {
                display: inline-block;
                max-width: calc(478rem/75);
            }
        }
        .icon-right {
            width: calc(28rem/75);
        }
        &>li:last-child {
            border-bottom: none;
        }
        .li-4 {
            border-bottom: none;
        }
    }
    .filter {
        width: calc(538rem/75);
        height: calc(32rem/75);
        background: #FFFFFF;
        filter: blur(calc(10rem/75));
        position: absolute;
        bottom: calc(74rem/75);
        left: calc(136rem/75);
    }
    .look-more {
        text-align: center;
        width: calc(136rem/75);
        height: calc(48rem/75);
        border-radius: calc(24rem/75);
        border: calc(2rem/75) solid #DCDFE6;
        position: absolute;
        bottom: calc(20rem/75);
        left: calc(279rem/75);
        font-size: calc(25rem/75);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.card-height {
    height: calc(653rem/75);
}
.none {
    display: none !important;
}
