.mobile{
   .mobile-mask{
        width: calc(520rem/75);
        height: calc(548rem/75);
        background: rgba(62, 70, 77, 0.8);
        box-shadow: calc(0rem/75) calc(2rem/75) calc(20rem/75) calc(0rem/75) rgba(0, 0, 0, 0.1);
        border-radius: calc(16rem/75);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 99;
        overflow: hidden;
        .maikefeng {
            width: calc(344rem/75);
            height: calc(344rem/75);
            margin: calc(24rem/75) auto;
        }
        &>div:nth-child(2) {
            font-size: calc(36rem/75);
            font-weight: 600;
            color: #FFFFFF;
            line-height: calc(50rem/75);
            text-align: center;
        }
        &>div:nth-child(3) {
            font-size: calc(22rem/75);
            font-weight: 300;
            color: #FFFFFF;
            line-height: calc(34rem/75);
            text-align: center;
            margin-top: calc(20rem/75);
        }
   }
   
}