.allAccounts {
    display: flex;
    margin-top: calc(24rem/75);
    &>div {
        margin-right: 8px;
        width: 104px;
        height: 58px;
        background: #F3F6FA;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 21px;
        cursor: pointer;
    }
    &>div:last-child {
        margin-right: 0px;
    }
    .normal {
        cursor: default;
    }
}