.v2300MobileZKGJProjectBudgetContract{
  background: #FFFFFF;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;

  .remainingMoneyTxt{
    color: #FFAA00;
  }

  .setBudgetTxt{
    color: rgb(51, 119, 255); 
    cursor: pointer;
  }
  .setBudgetTxtIcon{
    color: rgb(51, 119, 255); 
    cursor: pointer;
    margin-left: calc(10rem/75);
  }

  .setBudgetMain{
    color: rgb(51, 119, 255); 
    cursor: pointer;
    .budgetInputV2300Mobile{
      width: calc(300rem/75);
      min-height: calc(20rem/75);
      .adm-input-element{
        font-size: calc(24rem/75);
        color: #333333;
        border-bottom: calc(2rem/75) solid #E9EBEF;
        height: calc(20rem/75);
        line-height: calc(64rem/75);
      }
    }
  }



  .budgetList{
    margin-bottom: calc(4rem/75);
    word-break: break-all;
    .content-item{
      margin: calc(20rem/75) 0;
      padding: calc(24rem/75);
      box-sizing: content-box;
      border-radius: calc(16rem/75);
      opacity: 1;
      border: calc(2rem/75) solid #E9EBEF;
      .item-hd{
        display: flex;
        justify-content: space-between;
        .title{
          max-width: calc(358rem/75);
          font-weight: 700;
          color: #303133;
          margin-right: calc(5rem/75);
        }
        .contract-number{
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: calc(24rem/75);
          color: #909399;
          cursor: pointer;
          img{
            margin-left: calc(12rem/75);
            width: calc(24rem/75);
            height: calc(24rem/75);
          }
          .img-up{
            transform: rotateX(-180deg);
          }
        } 
      }

 
      &>div:last-child{
        margin-bottom: 0;
      }
    }
    &>div:last-child{
      margin-bottom: 0;
    }
  }

  .budgerFlex{
    display: flex;
    align-items: center;
  }

  .mt8{
    margin-top: calc(8rem/75);
  }

  .mt16{
    margin-top: calc(16rem/75);
  }

  .selLinkContract{
    margin-top: calc(16rem/75);
    font-size: calc(24rem/75);
    color: #3377FF;
    cursor: pointer;
  }

  .v2300MObileContractTipBox{
    max-width: calc(610rem/75);
    .ant-tooltip-inner{
      font-size: calc(24rem/75);
    }
  }
  .contractTipImg{
    // position: absolute;
    width: calc(28rem/75);;
    height: calc(28rem/75);;
    right: calc(24rem/75);;
    top: calc(24rem/75);;
    cursor: pointer;
    z-index: 5;
  }

  .mlr8{
    margin: 0 calc(8rem/75);
  }

  .chilrldContract-content{
    font-size: calc(24rem/75);
    padding: calc(24rem/75);
    box-sizing: content-box;
    border-radius: calc(12rem/75);
    border: calc(2rem/75) solid #E9EBEF;
    word-break: break-all;
    margin: calc(16rem/75) 0;
    background: #F3F6FA;
    color: #333333;
    
    .contract-tip{
      display: flex;
      justify-content: space-between;
    }
    .contract-name{
      font-weight: 700;
      margin-right: calc(10rem/75);
    }

    &>div:last-child{
      margin-bottom: 0;
    }
  }

  .chilrldContract-content:last-child{
    margin-bottom: 0 !important;
  }

}